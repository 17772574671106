import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const UpdateUnit = () => {
    const { unit_id } = useParams();
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.get(`${baseURL}/api/measurement/${unit_id}`,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        }) 
          .then(res => {
            setData({
              unit_id: res.data.unit_id,
              unit_name: res.data.unit_name
            });
          })
          .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        });
      }, [unit_id]);  

    const [data, setData] = useState({
        unit_id: "",
        unit_name: ""
      });

    const handleChange =(e)=>{
        setData({...data, [e.target.name]: e.target.value})
    }  
//..................................................... 
    const saveUnit = (e)=>{
        e.preventDefault()
     const token = sessionStorage.getItem("accessToken")

     setLoading(true);

        axios.patch(`${baseURL}/api/measurement/` + unit_id, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
            // .then(res=>console.log(res))
            .then(res =>{
                toast.success('Unit of measurement updated successfully',{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                })
                navigate('/UnitOfMeasurement')
            })
            
            // .catch(err=>console.log(err.message))    
            .catch(err=>{
                toast.error('An error occurred while updating the record.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  })
            }).finally(()=>{
                setLoading(false);
            })      
    }
    
    return (
    <div>
        {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
        
        <Form onSubmit={saveUnit}>   
                
                <h5 className="mb-3">Update unit of Measurement</h5>

                        <Form.Group className="mb-3" controlId="unit_Id">
                        <Form.Label>Unit Id:</Form.Label>
                        <Form.Control
                            type="input"
                            required onChange={handleChange}
                            value={data.unit_id}
                            name="unit_id"
                            disabled="disabled"
                        />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="unit">
                        <Form.Label>Unit of Measurement:</Form.Label>
                        <Form.Control
                            type="input"
                            placeholder="Enter unit of Measurement"
                            required onChange={handleChange}
                            value={data.unit_name}
                            name="unit_name"
                        />
                        </Form.Group>
                   
                    <Button variant="primary" type='submit'>
                        Update Unit
                    </Button>
                    <ToastContainer/>

        </Form>
    </div> 
    );
      
}
 
export default UpdateUnit;


