import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import Dashboard from "./Dashboard";
// import Buttons from "./Buttons";
import Footer from "./Footer";
// import Tables from "./Tables";
// import Charts from "./Charts";
import Error from "./Pages/Error";
import Blank from "./Pages/Blank";
// import Cards from "./Cards";
// import Other from "./Other";
// import Colors from "./Colors";
// import Borders from "./Borders";
// import Animation from "./Animation";
import AddVendor from "./AddVendor";
import StoreVendor from "./StoreVendor";
import AllVendors from "./AllVendors";
import AllStoreVendors from "./AllStoreVendors";
import UpdateVendor from "./UpdateVendor";
import UpdateStoreVendor from "./UpdateStoreVendor";
import VendorDetails from "./VendorDetails";
import UnitOfMeasurement from "./unitOfMeasurement";
import UpdateUnit from "./UpdateUnit";
import AddMaterial from "./AddMaterial";
import Category from "./AddCategory";
import DeleteVendor from "./DeleteVendor";
import ReceiveNewStock from "./ReceiveNewStock";
import ReceiveStockStore from "./ReceiveProduct";
import DeleteUnit from "./DeleteUnit";
import DeleteMaterial from "./DeleteMaterial";
import CreateProduct from "./CreateProduct";
import ShowStock from "./ShowStock";
import { RequireAuth } from "./RequireAuth";
import ShowReceivedStock from "./ShowReceivedStock";
import RawMaterialUsage from "./RawMaterialUsage";
import AddStore from "./AddStore";
import MatanThree from "./MatanThree";
import StoreVendorDetails from "./StoreVendorDetails";
import StoreVendorDelete from "./DeleteStoreVendor";
import ReadyMadeProduct from "./ReadyMadeProduct";
import ReceiveProduct from "./ReceiveProduct";
import ShowReceivedProduct from "./ShowReceivedProduct";
import MatanOneMakeSale from "./MatanOneMakeSale";
import ShowMatanOneSales from "./ShowMatanOneSales";
import MatanOneStock from "./MatanOneStock";
import MatanTwoMakeSale from "./MatanTwoMakeSale";
import MatanThreeMakeSale from "./MatanThreeMakeSale";
import ShowMatanTwoSales from "./ShowMatanTwoSales";
import ShowMatanThreeSales from "./ShowMatanThreeSales";
import MatanThreeStock from "./MatanThreeStock";
import MatanTwoStock from "./MatanTwoStock";
import CreateUser from "./CreateUser";
import UserManagement from "./UserManagement";
import UpdateUser from "./UpdateUser";
import UpdateMaterial from "./UpdateMaterial";
import WorkshopVendorPayment from "./WorkShopVendorPayment";
import StoreVendorPayment from "./StoreVendorPayment";
import AllWorkshopVendorPayment from "./AllWorkshopVendorPayment";
import AllStoreVendorPayment from "./AllStoreVendorPayment";
import MakeSaleInstallments from "./MakeSaleInstallments";
import UpdateReadyMadeProduct from "./UpdateReadyMadeProduct";
import UpdatePaymentInstallement from "./UpdatePaymentInstallement";
import ShowSaleInstallementBreakDown from "./ShowSaleInstallementBreakDown";
import MatanOneProducedMakeSale from "./MatanOneProducedMakeSale";
import ShowMatanOneSalesProduced from "./ShowMatanOneSalesProduced";
import ShowMatanOneStockProduced from "./ShowMatanOneStockProduced";
import MatanTwoProducedMakeSale from "./MatanTwoProducedMakeSale";
import ShowMatanTwoSalesProduced from "./ShowMatanTwoSalesProduced";
import ShowMatanTwoStockProduced from "./ShowMatanTwoStockProduced";
import MatanThreeProducedMakeSale from "./MatanThreeProducedMakeSale";
import ShowMatanThreeSalesProduced from "./ShowMatanThreeSalesProduced";
import ShowMatanThreeStockProduced from "./ShowMatanThreeStockProduced";
import AddCustomer from "./AddCustomer";
import UpdateCustomer from "./UpdateCustomer";
import AuthorizationError from "./AuthorizationError";
import ShowTransferProductReceived from "./ShowTransferProductReceived";
import ShowTransferProductReceived2 from "./ShowTransferReceived2";
import ShowTransferProductReceived3 from "./ShowTransferReceived3";
import ForgotPassword from "./Pages/ForgotPassword";
import Matan2SalesReport from "./Matan2SalesReport";
import Matan1SalesReport from "./Matan1SalesReport";
import Matan3SalesReport from "./matan3SalesReport";
import MatanReport from "./MatanReport";
import Matan2Report from "./Matan2Report";
import Matan3Report from "./Matan3Report";

export default function Main() {

  
  return (
    <>
    <Outlet/>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <Routes>
              <Route element={<RequireAuth/>}>
                <Route path="" element={ <Dashboard /> } />
                <Route path="Dashboard" element={ <Dashboard /> } />
                <Route path="error" element={<Error />} />
                <Route path="blank" element={<Blank />} />
              
                
                <Route path="AddVendor" element={<AddVendor/>} />
                <Route path="StoreVendor" element={<StoreVendor/>} />
                <Route path="AllVendors" element={<AllVendors/>} />
                <Route path="AllStoreVendors" element={<AllStoreVendors/>} />
                <Route path="UpdateVendor/:vendor_id" element={<UpdateVendor/>} />
                <Route path="UpdateStoreVendor/:vendor_id" element={<UpdateStoreVendor/>} />
                <Route path="VendorDetails/:vendor_id" element={<VendorDetails/>} />
                <Route path="StoreVendorDetails/:vendor_id" element={<StoreVendorDetails/>} />
                <Route path="DeleteStoreVendor/:vendor_id" element={<StoreVendorDelete/>} />
                <Route path="UnitOfMeasurement/" element={<UnitOfMeasurement/>} />
                <Route path="UpdateUnit/:unit_id" element={<UpdateUnit/>} />
                <Route path="Material" element={<AddMaterial/>} />
                <Route path="UpdateProduct/:product_id" element={<UpdateReadyMadeProduct/>} />
                <Route path="UpdateMaterial/:material_id" element={<UpdateMaterial/>} />
                <Route path="Category" element={<Category/>} />
                <Route path="DeleteVendor/:vendor_id" element={<DeleteVendor/>} />
                <Route path="ReceiveNewStock/" element={<ReceiveNewStock/>} />
                <Route path="ReceiveStockStore/" element={<ReceiveStockStore/>} />
                <Route path="DeleteUnit/:unit_id" element={<DeleteUnit/>} />
                <Route path="DeleteMaterial/:material_id" element={<DeleteMaterial/>} />
                <Route path="CreateProduct" element={<CreateProduct/>} />
                <Route path="Product" element={<ReadyMadeProduct/>} />
                <Route path="Stock" element={<ShowStock/>} />
                <Route path="ShowReceivedStock" element={<ShowReceivedStock/>} />
                <Route path="RawMaterialUsage" element={<RawMaterialUsage/>} />
                <Route path="Store" element={<AddStore/>} />
                <Route path="/Store/MatanThree" element={<MatanThree/>} />
                <Route path="/ReceiveProduct" element={<ReceiveProduct/>} />
                <Route path="/ShowReceiveProduct" element={<ShowReceivedProduct/>} />
                <Route path="/MakeSale1" element={<MatanOneMakeSale/>} />
                <Route path="/MakeSale2" element={<MatanTwoMakeSale/>} />
                <Route path="/MakeSale3" element={<MatanThreeMakeSale/>} />
                <Route path="/MatanOneSales" element={<ShowMatanOneSales/>} />
                <Route path="/MatanTwoSales" element={<ShowMatanTwoSales/>} />
                <Route path="/MatanThreeSales" element={<ShowMatanThreeSales/>} />
                <Route path="/MatanOneStock" element={<MatanOneStock/>} />
                <Route path="/MatanTwoStock" element={<MatanTwoStock/>} />
                <Route path="/MatanThreeStock" element={<MatanThreeStock/>} />
                <Route path="/createUser" element={<CreateUser/>} />
                <Route path="/UserManagement" element={<UserManagement/>} />
                <Route path="/UpdateUser/:id" element={<UpdateUser/>} />
                {/* <Route path="/UserManagement/DeleteUser" element={<UserManagement/>} /> */} AllWorkshopVendorPayment
                <Route path="/WorkShopVendorPayment" element={<WorkshopVendorPayment/>} />
                <Route path="/AllWorkShopVendorPayment" element={<AllWorkshopVendorPayment/>} />
                <Route path="/StoreVendorPayment" element={<StoreVendorPayment/>} />
                <Route path="/AllStoreVendorPayment" element={<AllStoreVendorPayment/>} />
                <Route path="/MakeSaleInstallment" element={<MakeSaleInstallments/>} />
                <Route path="/UpdatePaymentInstallement/:payment_id" element={<UpdatePaymentInstallement/>} />
                <Route path="/ShowSaleInstallement" element={<ShowSaleInstallementBreakDown/>} />
                <Route path="/MakeSale1Produced" element={<MatanOneProducedMakeSale/>} />
                <Route path="/MatanOneSalesProduced" element={<ShowMatanOneSalesProduced/>} />
                <Route path="/MatanOneStockProduced" element={<ShowMatanOneStockProduced/>} />

                <Route path="/MakeSale2Produced" element={<MatanTwoProducedMakeSale/>} />
                <Route path="/MatanTwoSalesProduced" element={<ShowMatanTwoSalesProduced/>} />
                <Route path="/MatanTwoStockProduced" element={<ShowMatanTwoStockProduced/>} />

                <Route path="/MakeSale3Produced" element={<MatanThreeProducedMakeSale/>} />
                <Route path="/MatanThreeSalesProduced" element={<ShowMatanThreeSalesProduced/>} />
                <Route path="/MatanThreeStockProduced" element={<ShowMatanThreeStockProduced/>} />
                <Route path="/AddCustomer" element={<AddCustomer/>} />
                <Route path="/UpdateCustomer/:customer_id" element={<UpdateCustomer/>} />
                <Route path="/AuthorizationError" element={<AuthorizationError/>} />
                <Route path="/ReceivedProducts" element={<ShowTransferProductReceived/>} />
                <Route path="/ReceivedProducts2" element={<ShowTransferProductReceived2/>} />
                <Route path="/ReceivedProducts3" element={<ShowTransferProductReceived3/>} />
                <Route path="/Matan2SalesReport" element={<Matan2SalesReport/>} />
                <Route path="/Matan1SalesReport" element={<Matan1SalesReport/>} />
                <Route path="/Matan3SalesReport" element={<Matan3SalesReport/>} />
                <Route path="/MatanReport" element={<MatanReport/>} />
                <Route path="/Matan2Report" element={<Matan2Report/>} />
                <Route path="/Matan3Report" element={<Matan3Report/>} />
              </Route>
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}


