import { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";


const DeleteUnit = () => {
    const { unit_id } = useParams();
    const baseURL = process.env.REACT_APP_BASE_URL

    useEffect(() => {
      const token = sessionStorage.getItem("accessToken")
      
        axios.get(`${baseURL}/api/measurement/` + unit_id,{
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }) 
          .then(res => {
            setData({
              unit_id: res.data.unit_id,
              unit_name: res.data.unit_name 
            });
          })
          .catch(err => console.log(err));
      }, [unit_id]);  

      const [data, setData]=useState({
            unit_name: ""
        })
    
    
    return (
        <div >
            <h5 className="text-danger pb-4 display-12">Are you Sure you want to Delete the Record Below ?</h5>

                     <h5 className="pb-4 display-12">  Unit id: {data.unit_id}  </h5>
                     
                     <h5 className="pb-4 display-12" >  Unit name: {data.unit_name} </h5>                  
                  
                     <hr></hr>
                     <Button variant="danger" type="submit" className="mr-3">
                    yes delete unit
                    </Button> 

                    <Button variant="light" type="submit" className="mr-3">
                    no take me back
                    </Button> 
        </div>
     );
}
 
export default DeleteUnit;