import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabContent, Form, Button, Col, Row, ToastContainer } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import HandleProduce1 from './HandleProduce1';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

function CreateProduct() {
  const [key, setKey] = useState('tab1');

  const [items, setItems] = useState([
    { materialId: '', unitOfMeasurement: '', quantityUsed: '' },
  ]);

  const baseURL = process.env.REACT_APP_BASE_URL
  const [loading, setLoading] = useState(false);

  // const addInput = () => {
  //   const newItem = { materialId: '', unitOfMeasurement: '', quantityUsed: '' };
  //   setItems([...items, newItem]);
  // };

  // const removeInput = (index) => {
  //   const updatedItems = [...items];
  //   updatedItems.splice(index, 1);
  //   setItems(updatedItems);
  // };

  const handleUnit = (e, index) => {
    const { name, value } = e.target;
    const updatedRawMaterials = [...selectedRawMaterials];
    updatedRawMaterials[index][name] = value;
    setSelectedRawMaterials(updatedRawMaterials);
  };

  const addInput = () => {
    setSelectedRawMaterials([...selectedRawMaterials, {}]);
  };

  const removeInput = (index) => {
    const updatedRawMaterials = [...selectedRawMaterials];
    updatedRawMaterials.splice(index, 1);
    setSelectedRawMaterials(updatedRawMaterials);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedItems = [...items];
    updatedItems[index][name] = value;
    setItems(updatedItems);
  };

  const [materials, setMaterials] = useState([]);
  const [selectedMaterialIds, setSelectedMaterialIds] = useState(Array(items.length).fill(''));

  useEffect(() => {
    const token = sessionStorage.getItem("accessToken")

    axios.get(`${baseURL}/api/Material/allMaterial`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(res => setMaterials(res.data))
      .catch(err => console.log(err));
  }, []);

  // const handleChange = (e, index) => {
  //   const newSelectedMaterialIds = [...selectedMaterialIds];
  //   newSelectedMaterialIds[index] = e.target.value;
  //   setSelectedMaterialIds(newSelectedMaterialIds);
  // };

  const handleChange = (e, index) => {
    
    const { name, value } = e.target;
    const updatedRawMaterials = [...selectedRawMaterials];
    updatedRawMaterials[index][name] = value;
    setSelectedRawMaterials(updatedRawMaterials);

  };
  

  const [measurements, setMeasurement] = useState([]);
  // const [selectedMasurementId, setSelectedMasurementId] = useState(Array(items.length).fill(''));

  useEffect(() => {
    const token = sessionStorage.getItem("accessToken")

    setLoading(true);
    axios.get(`${baseURL}/api/measurement/allMeasurements`,{
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(res => setMeasurement(res.data))
      .catch(err => console.log(err)).finally(()=>{
        setLoading(false);
      });
  }, []);

//...........................code to create product..............................................
const [data, setData]=useState({
  productname: "",
  // quantityProduced: "",
})

const handleCreate =(e)=>{
  setData({...data, [e.target.name]: e.target.value})
}

const CreateProduct =(e)=>{
    e.preventDefault()

    const requestData = { ...data };
    const token = sessionStorage.getItem("accessToken")
    setLoading(true);

    axios.post(`${baseURL}/api/product/addProduct`, requestData,{
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
        // .then(res=>console.log(res))
        .then(res =>{

            // Update the list of records after successfully adding a new product
            setProduct([...products, res.data]);

            alert('Product added for production');
        })
        // .catch(err=>console.log(err.message))    
        .catch(err=>{
          if (err.response && err.response.status === 400) {
            
            alert('Product already exists.');
              } else {
            //generic error message
            toast.error('An error occurred while adding the record.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        }).finally(()=>{
          
          setLoading(false);
      })
   
  }

  //....................code to produce product...................................................

  const [info, setInfo]=useState({
    product_id: 0,
    material_id: 0,
    quantityUsed: 0.00,
  })
  
  const [labourCost, setLabourCost] = useState(0);
  const [otherExpenses, setOtherExpenses] = useState(0);

  const handleProduce =(e)=>{
    // setInfo({...info, [e.target.name]: e.target.value})
    setInfo([...info, {material_id: 0, quantityUsed: 0.00}])
  }
  
  const CreateProduce =(e)=>{
      e.preventDefault()

  const labourCostValue = parseFloat(e.target.elements.labourCost.value) || 0;
  const otherExpensesValue = parseFloat(e.target.elements.otherExpenses.value) || 0;

  // Set the values in the state
  setLabourCost(labourCostValue);
  setOtherExpenses(otherExpensesValue);
  
      const formData = {
        product_id: selectedProductId,
        storeId: selectedStoreId,
        quantityProduced: data.quantityProduced,
        labourCost: labourCostValue,
        otherExpenses: otherExpensesValue,
        items: selectedRawMaterials.map((rawMaterial) => ({
          material_id: rawMaterial.material_id,
          unit_id: rawMaterial.unit_id,
          quantity: rawMaterial.quantityUsed,
        })),
      };

      const token = sessionStorage.getItem("accessToken")

      setLoading(true);

      axios.post(`${baseURL}/api/product/produce`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
          .then(res=>console.log(formData))
          .then(res =>{
              // toast.success('Production Successfull',{
              //     position: toast.POSITION.TOP_RIGHT,
              //     autoClose: 3000,
              // })
              alert('Production Successfull')
          })
          // .catch(err=>console.log(err.message))    
          .catch(err=>{
              toast.error('An error occurred while adding the record.', {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                })
          }).finally(()=>{
            setLoading(false);
        })     
    }
  
  
//.........................................................................

const [selectedRawMaterials, setSelectedRawMaterials]= useState([]);

const [products, setProduct] = useState([]);
const [selectedProductId, setSelectedProductdId]= useState('')
  
  useEffect(() => {
    const token = sessionStorage.getItem("accessToken")

    axios.get(`${baseURL}/api/product/allProducts`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(res => setProduct(res.data))
      .catch(err => console.log(err));
  }, []);

  //...............................................................
  const [stores, setStores] = useState([]);
const [selectedStoreId, setSelectedStoreId]= useState('')
  
  useEffect(() => {
    const token = sessionStorage.getItem("accessToken")
    
    axios.get(`${baseURL}/api/Store/allStore`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(res => setStores(res.data))
      .catch(err => console.log(err));
  }, []);
  // const handleProduct = (e) => {
    
  //   setSelectedProductdId(e.target.value);

    // setInfo({
    //   product_id: selectedProductId, // Update the product_id property
    //   MaterialMaterialId: selectedProduct ? selectedProduct.MaterialMaterialId : "",
    //   quantityUsed: selectedProduct ? selectedProduct.quantityUsed : "",
    // });  

  //   setInfo({
  //     product_id: selectedProductId,
  //   })
  // };

  const handleProduct = (e) => {
    const selectedProductId = e.target.value;
    setSelectedProductdId(selectedProductId);
    setSelectedRawMaterials([]);
  }

  const handleStore = (e) => {
    const selectedStoreId = e.target.value;
    // selectedStoreId(setSelectedStoreId)
    setSelectedStoreId(selectedStoreId);
    // setSelectedRawMaterials([]);
  }
  
 //............................Create Product.............................................

  return (
    <Tabs
      id="controlled-tabs"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
   

      <Tab eventKey="tab1" title="Create Product">
        <TabContent className='mt-5'>

        {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

        <Form onSubmit={CreateProduct}>
            <Form.Group className='mb-3'>
                <Form.Label> Product Name:</Form.Label>
                <Form.Control onChange={handleCreate} required type="text" name="productname" placeholder='Enter product name' />
            </Form.Group>

            {/* <Form.Group className='mb-3'>
                <Form.Label> Quantity Produced:</Form.Label>
                <Form.Control onChange={handleCreate} required type="text" name="quantityProduced" placeholder='Enter quantity produced' />
            </Form.Group> */}

            <Button className='mt-3' type="submit">Create product</Button>
        </Form>
        </TabContent>
      </Tab>
      {/* ..........................Produce Product................................................ */}
      {/* <Tab eventKey="tab2" title="Produce Product">
        <TabContent className='mt-5'>
          <div>
          <Form onSubmit={CreateProduce}>
      <div className='row'>
        
          <Col>
            <Form.Label>Select Product to produce:</Form.Label>
            <select className='form-control mb-4 col-md-8' name="product_id" value={selectedProductId} onChange={handleProduct}>
              <option value="">Select Product</option>
              {products.map(product => (
                <option key={product.id} value={product.product_id}>
                  {`${product.product_id}- ${product.productname}`}
                </option>
              ))}
            </select>
          </Col>
          <Col>
            <Form.Group className='mb-3 col-md-8'>
                <Form.Label> Quantity Produced:</Form.Label>
                <Form.Control onChange={handleCreate} required type="number" name="quantityProduced" placeholder='Enter quantity produced' />
            </Form.Group>
          </Col>

          <Col>
            <Form.Label>Select Store:</Form.Label>
            <select className='form-control mb-4 col-md-7' name="store_id" value={selectedStoreId} onChange={handleStore}>
              <option value="">Select Store</option>
              {stores.map(store => (
                <option key={store.id} value={store.store_id}>
                  {`${store.store_id}- ${store.store_name}`}
                </option>
              ))}
            </select>
          </Col>
       
      </div>

      <div>
        <b><p>RAW MATERIALS</p></b>
        {selectedRawMaterials.map((rawMaterial, index) => (
          <Row key={index}>
            <Col>
              <select className='form-control mb-4' value={rawMaterial.material_id || ''} name="material_id" onChange={(e) => handleChange(e, index)}>
                <option value="">Select Material</option>
                {materials.map(material => (
                  <option key={material.id} value={material.material_id}>
                    {`${material.material_id} - ${material.material_name}`}
                  </option>
                ))}
              </select>
            </Col>
            <Col>
              <select className='form-control mb-3' value={rawMaterial.unit_id || ''} name="unit_id" onChange={(e) => handleUnit(e, index)}>
                <option value="">Select Unit of Measurement</option>
                {measurements.map(measurement => (
                  <option key={measurement.id} value={measurement.id}>
                    {`${measurement.unit_id} - ${measurement.unit_name}`}
                  </option>
                ))}
              </select>
            </Col>
            <Col>
              <Form.Control
                type="number"
                placeholder="Quantity Used"
                name="quantityUsed"
                value={rawMaterial.quantityUsed || ''}
                onChange={(e) => handleChange(e, index)}
              />
            </Col>
            <Col>
              <Button variant="danger" onClick={() => removeInput(index)}>
                - Remove
              </Button>
            </Col>
          </Row>
        ))}
        <Button className="mb-3" variant="success" onClick={addInput}>
          Add Raw Material
        </Button>
      </div>
      <Button type="submit">Produce Product</Button>
    </Form>
         </div>
        </TabContent>
      </Tab> */}

<Tab eventKey="tab2" title="Produce Product">
  <TabContent className="mt-5">
    
  {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

    <Form onSubmit={CreateProduce}>
      <div className="row">
        <Col md={4}>
          <Form.Label>Select Product to produce:</Form.Label>
          <select
            className="form-control mb-4"
            name="product_id"
            value={selectedProductId}
            onChange={handleProduct}
          >
            <option value="">Select Product</option>
            {products.map((product) => (
              <option key={product.id} value={product.product_id}>
                {`${product.product_id}- ${product.productname}`}
              </option>
            ))}
          </select>
        </Col>
        <Col md={4}>
          <Form.Label>Quantity Produced:</Form.Label>
          <Form.Control
            onChange={handleCreate}
            required
            type="number"
            name="quantityProduced"
            placeholder="Enter quantity produced"
          />
        </Col>
        <Col md={4}>
          <Form.Label>Select Store:</Form.Label>
          <select
            className="form-control mb-4"
            name="store_id"
            value={selectedStoreId}
            onChange={handleStore}
          >
            <option value="">Select Store</option>
            {stores.map((store) => (
              <option key={store.id} value={store.store_id}>
                {`${store.store_id}- ${store.store_name}`}
              </option>
            ))}
          </select>
        </Col>
      </div>

      <div>
        <b>
          <p>RAW MATERIALS</p>
        </b>
        {selectedRawMaterials.map((rawMaterial, index) => (
          <div key={index} className="row">
            <Col md={4}>
              <select
                className="form-control mb-4"
                value={rawMaterial.material_id || ""}
                name="material_id"
                onChange={(e) => handleChange(e, index)}
              >
                <option value="">Select Material</option>
                {materials.map((material) => (
                  <option key={material.id} value={material.material_id}>
                    {`${material.material_id} - ${material.material_name}`}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={4}>
              <select
                className="form-control mb-3"
                value={rawMaterial.unit_id || ""}
                name="unit_id"
                onChange={(e) => handleUnit(e, index)}
              >
                <option value="">Select Unit of Measurement</option>
                {measurements.map((measurement) => (
                  <option key={measurement.id} value={measurement.id}>
                    {`${measurement.unit_id} - ${measurement.unit_name}`}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={3}>
              <Form.Control
                type="number" 
                step="0.01" 
                placeholder="Quantity Used"
                name="quantityUsed"
                value={rawMaterial.quantityUsed || ""}
                onChange={(e) => handleChange(e, index)}
              />
            </Col>
            <Col md={1}>
              <Button variant="danger" onClick={() => removeInput(index)}>
                - 
              </Button>
            </Col>
          </div>
        ))}
         
        <Button className="mt-3 mr-2" variant="success" onClick={addInput}>
          Add Raw Material
        </Button>

        <Button className="mt-3" type="submit">
          Produce Product
        </Button>
        <Col md={12} className="d-flex">

      <Form.Label>Labour Cost:</Form.Label>   
    <Form.Control
    
        type="number"
        step="0.01"
        placeholder="Enter Labour cost"
        name="labourCost"
        // value={labourCost}
        onChange={(e) => setLabourCost(parseFloat(e.target.value) || 0)}
        className="mr-2 mt-3"
      />
      <Form.Label>Other Expenses:</Form.Label>  
      <Form.Control
        type="number"
        step="0.01"
        placeholder="Enter other Expenses"
        name="otherExpenses"
        // value={otherExpenses}
        onChange={(e) => setOtherExpenses(parseFloat(e.target.value) || 0)}
        className="mt-3"
      />
    </Col>
      </div>
    </Form>
  </TabContent>
</Tab>






     {/* .................................End Tab2.produce product.....................................................*/}
      {/* <Tab eventKey="tab3" title="Update Inventory">
        <TabContent>Content for Tab 3</TabContent>
        <HandleProduce1/>
      </Tab> */}
    </Tabs>
  );
  
}

export default CreateProduct;

// import React, { useState } from 'react';

// function CreateProduct() {
//   const [productName, setProductName] = useState('');
//   const [materials, setMaterials] = useState([
//     { materialId: '', uomId: '', quantity: '' },
//   ]);

//   // Function to handle changes in Material, UOM, and Quantity fields
//   const handleMaterialChange = (index, event) => {
//     const { name, value } = event.target;
//     const updatedMaterials = [...materials];
//     updatedMaterials[index][name] = value;
//     setMaterials(updatedMaterials);
//   };

//   // Function to add a new set of Material, UOM, and Quantity fields
//   const addMaterialField = () => {
//     setMaterials([...materials, { materialId: '', uomId: '', quantity: '' }]);
//   };

//   // Function to remove a set of Material, UOM, and Quantity fields
//   const removeMaterialField = (index) => {
//     const updatedMaterials = [...materials];
//     updatedMaterials.splice(index, 1);
//     setMaterials(updatedMaterials);
//   };

//   // Function to submit the form
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     // Do something with the data (e.g., send it to an API)
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label>Product Name:</label>
//         <input
//           type="text"
//           value={productName}
//           onChange={(e) => setProductName(e.target.value)}
//         />
//       </div>
//       {materials.map((material, index) => (
//         <div key={index}>
//           <label>Material ID:</label>
//           <input
//             type="text"
//             name="materialId"
//             value={material.materialId}
//             onChange={(e) => handleMaterialChange(index, e)}
//           />
//           <label>Unit of Measurement ID:</label>
//           <input
//             type="text"
//             name="uomId"
//             value={material.uomId}
//             onChange={(e) => handleMaterialChange(index, e)}
//           />
//           <label>Quantity:</label>
//           <input
//             type="text"
//             name="quantity"
//             value={material.quantity}
//             onChange={(e) => handleMaterialChange(index, e)}
//           />
//           <button type="button" onClick={() => removeMaterialField(index)}>
//             Remove
//           </button>
//         </div>
//       ))}
//       <button type="button" onClick={addMaterialField}>
//         Add Material
//       </button>
//       <button type="submit">Submit</button>
//     </form>
//   );
// }

// export default CreateProduct;