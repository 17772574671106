import { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const DeleteStoreVendor = () => {
    const { vendor_id } = useParams();
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);

    useEffect(() => {
       const token = sessionStorage.getItem("accessToken")

      //  axios.get('http://localhost:4000/api/StoreVendor/' + vendor_id, {

        setLoading(true);

        axios.get(`${baseURL}/api/StoreVendor/` + vendor_id, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }) 
          .then(res => {
            setData({
              vendor_id: res.data.vendor_id,
              firstname: res.data.firstname,
              lastname: res.data.lastname,
              phonenumber: res.data.phonenumber,
              email: res.data.email,
              address: res.data.address
            });
          })
          .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        })
;
      }, [vendor_id]);  

      const [data, setData]=useState({
            firstname: "",
            lastname: "",
            phonenumber: "",
            email: "",
            address: ""
        })
    
    
    return (
        <div >
          {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
          
            <h5 className="text-danger pb-4 display-12">Are you Sure you want to Delete the Record Below ?</h5>

                     <h5 className="pb-4 display-12">  Vendor id: {data.vendor_id}  </h5>
                     
                     <h5 className="pb-4 display-12" >  Firstname: {data.firstname} </h5>                  

                     <h5 className="pb-4 display-12">  Lastname: {data.lastname}</h5>
             
                     <h5 className="pb-4 display-12">  Phone Number: {data.phonenumber}</h5>
                     
                     <h5 className="pb-4 display-12">  Email: {data.email}</h5>
                    
                     <h5 className="pb-4 display-12"> Physical Address: {data.address} </h5>
                  
                     <hr></hr>
                     <Button variant="danger" type="submit" className="mr-3">
                    yes delete vendor
                    </Button> 

                    <Button variant="primary" type="submit" >
                    no don't delete
                    </Button> 
        </div>
     );
}
 
export default DeleteStoreVendor;