import Button from 'react-bootstrap/Button';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import PaginationComponent from './PaginationComponent';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthorizationError from './AuthorizationError';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';


const AllStoreVendorPayment = () => {

   

    const [records, setRecords]= useState([])
    const [unauthorized, setUnauthorized]= useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // number of items to be displayed per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = records.slice(indexOfFirstItem, indexOfLastItem);
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false); 

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
       
    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.get(`${baseURL}/api/StoreVendorPayment/storeVendorPayment`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
       
        .then(res =>{setRecords(res.data)})
        .catch(err => {
            if (err.response.status === 403) {
                setUnauthorized(true);
              }
        }).finally(()=>{
            setLoading(false);
        })
        
    }, [])
                  
    return (
        <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12'>
          {unauthorized ? (
            <AuthorizationError />
          ) : (
            <div>
             {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

            <h5> Store Vendor Payment </h5>
            <div className='table-responsive'>
                <table className="table table-bordered table-md">
                    <thead>
                        <tr>
                            <th>Vendor Name</th>
                            <th>Product Supplied</th>
                            <th>Description</th>
                            <th>Amount Paid</th>
                            <th>Date Paid</th>
                        </tr>
                    </thead> 
                    <tbody>
                        {currentData.map((r, i)=>(
                            <tr key={i}>
                            <td>{r.vendorname}</td>
                            <td>{r.productSupplied}</td>
                            <td>{r.Description}</td>
                            <td>{r.AmountPaid}</td>
                            <td>{new Date(r.createdAt).toDateString()}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <PaginationComponent
                currentPage={currentPage}
                totalItems={records.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />
        </div>
          )}
        </div>
      );
}
 
export default AllStoreVendorPayment;

