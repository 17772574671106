import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useEffect } from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import AuthorizationError from './AuthorizationError';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';


const UnitOfMeasurement = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [unauthorized, setUnauthorized] = useState(false);
  const baseURL = process.env.REACT_APP_BASE_URL
  const [loading, setLoading] = useState(false);

const [data, setData]=useState({
    unit_name: "",
})

const handleChange =(e)=>{
    setData({...data, [e.target.name]: e.target.value})
}

const saveUnit = (e)=>{
    e.preventDefault()

    const token = sessionStorage.getItem("accessToken")
    const requestData = { ...data };

    setLoading(true);

    axios.post(`${baseURL}/api/measurement/addMeasurement`, requestData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
    })
        // .then(res=>console.log(res))
        .then(res =>{
            toast.success('New unit of measurement added successfully',{
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            })
        })
        // .catch(err=>console.log(err.message))    
        .catch(err=>{
            toast.error('An error occurred while adding the record.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              })
        }).finally(()=>{
            setLoading(false);
        })      
}

const [records, setRecords]= useState([])
    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.get(`${baseURL}/api/measurement/allMeasurements`,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
        .then(res =>{setRecords(res.data)})
        .catch(err => {
            if (err.response.status === 403) {
                setUnauthorized(true);
            }
        }).finally(()=>{
            setLoading(false);
        })
    }, [])

    return (
    
    <div className="container-fluid">
    {unauthorized ? (
        <AuthorizationError />
    ) : (
    <>

    {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
    
        <div className="container-fluid">
            <Button variant="success" type="submit" onClick={handleShow} className='mb-3 btn-sm'>
                    ADD NEW UNIT OF MEASUREMENT
            </Button>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
            
            <Form onSubmit={saveUnit} >   
                <Modal.Header closeButton>
                <h6>add new Unit of Measurement</h6>
                </Modal.Header>
                    <Modal.Body>
                    
                        <Form.Group className="mb-3" controlId="unit">
                        <Form.Label>Unit of Measurement</Form.Label>
                        <Form.Control
                            type="input"
                            placeholder="Enter unit of Measurement"
                            required onChange={handleChange}
                            value={data.unit_name}
                            name="unit_name"
                            autoFocus
                        />
                        
                        </Form.Group>

                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type='submit'>
                        Add Unit
                    </Button>
                    <ToastContainer/>
                </Modal.Footer>
                </Form>
            </Modal>

        <div>
        {/* <div className="card">   */}
            {/* <div className="card-body">     */}
                <table className="table table-hover table-sm">
                    <thead>
                        <tr>
                            <th class="text-center" >Unit id</th>
                            <th class="text-center" >Unit name</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead> 
                    <tbody>
                        {records.map((r, i)=>(
                            <tr key={i}>
                            <td  class="text-center">{r.unit_id}</td>
                            <td  className="text-center">{r.unit_name}</td> 
                            {/* <td className="text-center mr-2" > <Link to={`/UpdateUnit/${r.unit_id}`} className="btn btn-primary btn-sm mr-1">Update</Link>

                            <Link to={`/DeleteUnit/${r.unit_id}`} className="btn btn-danger btn-sm mr-1">Delete</Link>
                            </td>  */}
                            <td className="text-center">
                            <Dropdown>
                                <Dropdown.Toggle variant="default" id="dropdown-basic" size="sm">
                                    Perform Actions
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Link to={`/UpdateUnit/${r.unit_id}`} className="dropdown-item">Edit Unit</Link>
                                    {/* <Link to={`/DeleteUnit/${r.unit_id}`} className="dropdown-item" >Delete</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            </td>
                        </tr>
                        ))}
                    
                    </tbody>
                
                </table>
            {/* </div> */}
        {/* </div> */}
       </div>
     </div>
        
    </>
    )}
</div>
    );

}
 
export default UnitOfMeasurement;

