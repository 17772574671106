import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useState, useEffect } from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const ReceiveProduct = () => {
    
        // const validate =(values)=>{
        //     const errors ={};
        //          const regex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        //         if(!values.firstname){
        //             errors.firstname ="Firstname is required!";
        //         }
        //         if(!values.lastname){
        //             errors.lastname ="Lastname is required!";
        //         }
        //         if(!values.phonenumber){
        //             errors.phonenumber ="Phonenumber is required!";
        //         }
        //         if(!values.email){
        //             errors.email ="Email is required!";
        //         }else if (!regex.test(values.email)){
        //             errors.email="This is not a valid email";
        //         }
        //         if(!values.address){
        //             errors.address ="Address is required!";
        //         }
        //         return errors;     
        // }
//............................................................................................................
        // const saveVendor = (e) => {
        //     e.preventDefault();
        //     const errors = validate(formValues);
        //     setFormErrors(errors);
        
            // if (Object.keys(errors).length === 0) {
            //     setIsSubmit(true); // Move this line here
        
            //     axios.post(`${baseURL}/api/vendor/addVendor', formValues)
            //         .then(res => {
            //             toast.success('New Vendor added successfully', {
            //                 position: toast.POSITION.TOP_RIGHT,
            //                 autoClose: 3000,
            //             });
            //             setFormValues(initialValues);
            //         })
            //         .catch(err => {
            //             toast.error('An error occurred while adding the record.', {
            //                 position: toast.POSITION.TOP_RIGHT,
            //                 autoClose: 3000,
            //             });
            //         });
            // }
        //};
        
        // code to bind product with drop down to get product id and name
        const [products, setProducts] = useState([]);
        const [selectedProductId, setSelectedProductId] = useState('');
        const baseURL = process.env.REACT_APP_BASE_URL
        const [loading, setLoading] = useState(false);

        useEffect(()=>{
                
                const token = sessionStorage.getItem("accessToken")

            setLoading(true);

            axios.get(`${baseURL}/api/productStore/allproducts`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
            })
            .then(res =>{setProducts(res.data)})
            
            .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        })
        }, [])

        const handleChange=(e)=>{
            const newSelectedProductId = e.target.value;
            setInfo({...info, product_id: newSelectedProductId})
            setSelectedProductId(newSelectedProductId);     
        };


        // code to bind vendor with drop down to get vendor id and name
        const [vendors, setVendors] = useState([]);
        const [selectedVendorId, setselectedVendorId] = useState('');
            useEffect(()=>{
             const token = sessionStorage.getItem("accessToken")

            axios.get(`${baseURL}/api/StoreVendor/allStoreVendors`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
            })
            .then(res =>{setVendors(res.data)})
            
            .catch(err => console.log(err))
        }, [])


        const [stores, setStores] = useState([]);
        const [selectedStoreId, setSelectedStoreId] = useState('');
            useEffect(()=>{

             const token = sessionStorage.getItem("accessToken")

            axios.get(`${baseURL}/api/Store/allStore`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
            })
            .then(res =>{setStores(res.data)})
            
            .catch(err => console.log(err))
        }, [])

        const handleQuantity=(e)=>{
            setInfo({...info, [e.target.name]: e.target.value})
        };

        const handleVendor = (e) => {
            const newSelectedVendorId = e.target.value;
            setInfo({ ...info, vendor_id: newSelectedVendorId });
            setselectedVendorId(newSelectedVendorId);
          };

        const handleStore = (e) => {
            const newSelectedStoreId = e.target.value;
            setInfo({ ...info, store_id: newSelectedStoreId });
            setSelectedStoreId(newSelectedStoreId);
          };


        const [info, setInfo]=useState({
            product_id: 0,
            QuantityReceived: 0,
            vendor_id: 0,
            store_id: 0,
          })
          
          const ReceiveProduct =(e)=>{
              e.preventDefault()
          
              const token = sessionStorage.getItem("accessToken")
              const requestData = { ...info };

              setLoading(true);

              axios.post(`${baseURL}/api/receiveProduct/addProduct`, requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
              })
                  // .then(res=>console.log(requestData))
                  .then(res =>{
                      toast.success('Product Received Successfull',{
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 3000,
                      })
                      setInfo({
                        QuantityReceived: 0,
                    });
                        setSelectedProductId('');
                        setselectedVendorId('');
                        setSelectedStoreId('');
                  })
                  // .catch(err=>console.log(err.message))    
                  .catch(err=>{
                      toast.error('An error occurred while adding the record.', {
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 3000,
                        })
                  }).finally(()=>{
                    setLoading(false);
                })      
            }
        
    return (
        <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12 p-5 rounded shadow'>
       
       {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

            <Form onSubmit={ReceiveProduct}>
            <h5 className='pb-3 display-12'> Receive Product - Store</h5>

            {/* code to bind material with drop down to get material id and name */}
            <Form.Label>Product Name:</Form.Label>
                <select className='form-control mb-3' name="product_id" value={selectedProductId} onChange={handleChange}>
                    <option value="">Select Product</option>
                    {products.map(product => (
                    <option key={product.id} value={product.product_id}>
                        {`${product.product_id} - ${product.productname}`}
                    </option>
                    ))}
                </select>
         
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Quantity Received:</Form.Label>
                    <Form.Control type="input" onChange={handleQuantity} name="QuantityReceived" />
                </Form.Group>

                {/*bind vendor with drop down to get vendor id and name */}
                <Form.Label>Vendor Name:</Form.Label>
                <select className='form-control mb-3' name="vendor_id" value={selectedVendorId} onChange={handleVendor}>
                    <option value="">Select a Vendor</option>
                    {vendors.map(vendor => (
                    <option key={vendor.id} value={vendor.vendor_id}>
                        {`${vendor.vendor_id} - ${vendor.firstname}  ${vendor.lastname}`}
                    </option>
                    ))}
                </select>

                {/*bind material inventory with drop down to get materialid */}
                {/* <Form.Label>Material Inventory:</Form.Label>
                <select className='form-control mb-3' value={selectedVmaterialInventoryId} onChange={handleInventory}>
                    <option value="">Select a Material Id</option>
                    {materialInventory.map(inventory => (
                    <option key={inventory.id} value={inventory.id}>
                        {`${inventory.material_id}`}
                    </option>
                    ))}
                </select> */}

                <Form.Label>Select Store Name:</Form.Label>
                <select className='form-control mb-3' name="store_id" value={selectedStoreId} onChange={handleStore}>
                    <option value="">Select Store</option>
                    {stores.map(store => (
                    <option key={store.id} value={store.store_id}>
                        {`${store.store_id} - ${store.store_name}`}
                    </option>
                    ))}
                </select>



                <Button variant="primary" type="submit">
                    Receive Product
                </Button>
                <ToastContainer/>
            </Form>
        </div>
    );
}
 
export default ReceiveProduct;

