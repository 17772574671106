import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const UpdatePaymentInstallement = () => {
    const { payment_id } = useParams();    
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);
    
// const [customers, setCustomers] = useState([]);
// const [selectedCustomerId, setSelectedCustomerId] = useState('');

useEffect(() => {
    const token = sessionStorage.getItem("accessToken");

    setLoading(true);

    axios.get(`${baseURL}/api/PaymentInstallement/getPayment/${payment_id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    })
    .then(res => {
        setData({
            payment_id: res.data.payment_id,
            customer_id: res.data.customer_id,
            AmountPaid: res.data.AmountPaid,
            balance: res.data.balance,
            TotalToPay: res.data.TotalToPay
        });
    })
    .catch(err => console.log(err)).finally(()=>{
        setLoading(false);
    })
}, [payment_id]);


    const [data, setData]=useState({
        payment_id: "",
        // product_name: "",
        // quantity: 0,
        // unitPrice: 0,
        AmountPaid: 0,
        // balance: 0,
        TotalToPay: "",
        customer_id: ""
      })
    

    // const handleChange=(e)=>{
    //     const newSelectedCustomerId = e.target.value;
    //     setInfo({...info, customer_id: newSelectedCustomerId})
    //     setSelectedCustomerId(newSelectedCustomerId);     
    // }
    // const handleChange =(e)=>{
    //     setData({...data, [e.target.name]: e.target.value})
    // }  
const handleInput=(e)=>{
    setData({...data, [e.target.name]: e.target.value})
};

//..................................................... 

    const MakePayment =(e)=>{
        e.preventDefault()
    
        const token = sessionStorage.getItem("accessToken")
        
        // const requestData = { ...info };

         //patch method to update
         setLoading(true);

        axios.patch(`${baseURL}/api/PaymentInstallement/updatePayment/` + payment_id, data, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
        })
          //   .then(res=>console.log(requestData))
            .then(res =>{
                toast.success('Payment Updated',{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                })
            })
            // .catch(err=>console.log(err.message))    
            .catch(err=>{
                toast.error('An error occurred while making the payment.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  })
            }).finally(()=>{
                setLoading(false);
            })      
      }
    
    return (
    <div>
        
       {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />} 

        {/* <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} > */}
             <Form onSubmit={MakePayment}>
                    {/* <Modal.Header closeButton> */}
                        <h5 className="mb-3">Update installements </h5>
                    {/* </Modal.Header> */}
                    {/* <Modal.Body> */}
                {/* code to bind material with drop down to get material id and name */}
                
                
                    {/* should get this from the drop down in the customer table will change to that */}
                    {/* <Form.Label>Customer Name:</Form.Label>
                    <select className='form-control mb-3' name="customer_name" value={selectedCustomerId} onChange={handleChange}>
                        <option value="">Select customer</option>
                        {customers.map(customer => (
                        <option key={customer.id} value={customer.customer_id}>
                            {`${customer.customer_id} - ${customer.customer_name}`}
                        </option>
                        ))}
                    </select>  */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Payment Id:</Form.Label>
                        <Form.Control type="text" onChange={handleInput} required value={data.payment_id}placeholder="Payment Id" name="payment_id" />
                    </Form.Group>   

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Customer:</Form.Label>
                        <Form.Control type="text" onChange={handleInput} required value={data.customer_id}placeholder="Customer Id" name="customer_id" />
                    </Form.Group>                    
            
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Amount Paid:</Form.Label>
                        <Form.Control type="number" required onChange={handleInput} placeholder="Amount Paid" name="AmountPaid" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        {/* <Form.Label>Balance:</Form.Label> */}
                        <Form.Control type="number" required value={data.balance} hidden disabled onChange={handleInput} placeholder="Balance" name="AmountPaid" />
                    </Form.Group>


                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        {/* <Form.Label>Total to Pay:</Form.Label> */}
                        <Form.Control type="number" required value={data.TotalToPay} hidden disabled onChange={handleInput} placeholder="Total to Pay" name="TotalToPay" />
                    </Form.Group>


                    
                    {/* </Modal.Body> */}
                    {/* <Modal.Footer> */}
                        {/* <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button> */}
                        <Button variant="primary" type="submit">
                            Update Payment
                        </Button>
                    <ToastContainer/>
                    {/* </Modal.Footer>    */}
            </Form>
            
            {/* </Modal> */}
    </div> 
    );
      
}
 
export default UpdatePaymentInstallement;


