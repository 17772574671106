import axios from "axios";
import { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const Matan1SalesReport = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reportData, setReportData] = useState(null);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false); 

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handlePrint = () => {
    window.print();
};

  const fetchReportData = async () => {
    // Check if both start date and end date are selected
    if (!startDate || !endDate) {
      console.error('Please select both start date and end date.');
      return;
    }

    try {
      // const response = await axios.get(`${baseURL}/api/Sales/matan-2-SalesReport?startDate=${startDate}&endDate=${endDate}`);
      const token = sessionStorage.getItem("accessToken")
      
      setLoading(true);

      const response = await axios.get(`${baseURL}/api/ProductionSale/matanReport`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },

        params: {
          startDate: startDate,
          endDate: endDate,
          storeId: 1
        } 
      });

      setReportData(response.data);

    } catch (error) {
      console.error('Error generating sales report:', error);
      // Handle error
    }finally{
        setLoading(false); 
    }
  }

  const getTotalSalesInRange = () => {
    let totalSales = 0;
    if (reportData) {
      for (const date in reportData.reportData) {
        if (date >= startDate && date <= endDate) {
          for (const product in reportData.reportData[date]) {
            totalSales += reportData.reportData[date][product].totalSales;
          }
        }
      }
    }
    return totalSales;
  };

  const formatDate = (dateString) => {
    const options = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  };

  const formatGrandTotalDates = (grandTotal) => {
    const formattedDates = {};
    for (const date in grandTotal) {
      formattedDates[formatDate(date)] = grandTotal[date];
    }
    return formattedDates;
  };

  return (
    <div id="printable-area" >
      
      {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

      <h5 className="mb-3">Matan One Sales Report -Produced Products</h5>
      
      <Form className="mb-3">
        <Row className="align-items-end">
            <Col md={3}>
              <Form.Group controlId="startDate">
                <Form.Label>Start Date:</Form.Label>
                <Form.Control type="date" value={startDate} onChange={handleStartDateChange} />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group controlId="endDate">
                <Form.Label>End Date:</Form.Label>
                <Form.Control type="date" value={endDate} onChange={handleEndDateChange} />
              </Form.Group>
            </Col>

            <Col md={3} className="not-printable">
              <Button className="mr-3" variant="outline-success"  onClick={fetchReportData}>Generate Report</Button>
              <Button variant="outline-primary"  onClick={handlePrint}>Print Report</Button>
            </Col>
        </Row>
      </Form>

      {reportData && (
        <div >
          <table className="table" >
            <thead>
              <tr>
                <th>Date</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Total Sales</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(reportData.reportData).map(([date, products]) => (
                Object.entries(products).map(([productName, productData]) => (
                  <tr key={`${date}-${productName}`}>
                    <td>{formatDate(date)}</td>
                    <td>{productName}</td>
                    <td>{productData.quantity}</td>
                    <td>{productData.totalSales}</td>
                  </tr>
                ))
              ))}
            </tbody>
          </table>

          <p className="grandTotal">Grand Total: {JSON.stringify(formatGrandTotalDates(reportData.grandTotal))}</p>
         <b> <p className="totalSales">Total Sales for Selected Range: {getTotalSalesInRange()}</p> </b>
        
        </div>
      )}
    </div>
    
  );
  
}

export default Matan1SalesReport;

    
    
