import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Form } from 'react-bootstrap';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const MatanTwoMakeSale = () => {
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [info, setInfo] = useState({
    product_id: 0,
    Quantity: 0,
    unitPrice: 0,
    Total: 0,
  });
  const baseURL = process.env.REACT_APP_BASE_URL
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');

    setLoading(true);

    axios.get(`${baseURL}/api/productStore/allproducts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => setProducts(res.data))
      .catch((err) => console.log(err)).finally(()=>{
            setLoading(false);
        })
;
  }, []);

  const handleChange = (e) => {
    const newSelectedProductId = e.target.value;
    setSelectedProductId(newSelectedProductId);

    const selectedProduct = products.find((product) => product.product_id === parseInt(newSelectedProductId, 10));

    if (selectedProduct) {
      setInfo({
        ...info,
        product_id: selectedProduct.product_id,
        unitPrice: selectedProduct.price,
      });
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInfo({
      ...info,
      [name]: value,
      Total: calculateTotal(value, info.unitPrice),
    });
  };

  const calculateTotal = (quantity, unitPrice) => {
    const total = parseFloat(quantity) * parseFloat(unitPrice);
    return isNaN(total) ? 0 : total.toFixed(2);
  };

  const MakeSale = (e) => {
    e.preventDefault();

    const token = sessionStorage.getItem('accessToken');

    const requestData = { ...info };

    setLoading(true);

    axios.post(`${baseURL}/api/Sales/makeSale2`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        toast.success('New Sale made', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        toast.error('An error occurred while making the sale.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }).finally(()=>{
        setLoading(false);
    })
  };

  return (
    <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12 p-5 rounded shadow'>
     
     {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
     
      <Form onSubmit={MakeSale}>
        <h5 className='pb-3 display-12'> Make Sale - Matan Two</h5>

        <Form.Label>Product Name:</Form.Label>
        <select className='form-control mb-3' name="product_id" value={selectedProductId} onChange={handleChange}>
          <option value="">Select Product</option>
          {products.map((product) => (
            <option key={product.id} value={product.product_id}>
              {`${product.product_id} - ${product.productname}`}
            </option>
          ))}
        </select>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Quantity:</Form.Label>
          <Form.Control type="number" onChange={handleInput} placeholder="Quantity" name="Quantity" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Unit price:</Form.Label>
          <Form.Control type="number" value={info.unitPrice} readOnly />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Total:</Form.Label>
          <Form.Control type="number" value={info.Total} readOnly />
        </Form.Group>

        <Button variant="primary" type="submit">
          Make Sale
        </Button>
        <ToastContainer />
      </Form>
    </div>
  );
};

export default MatanTwoMakeSale;


