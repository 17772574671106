import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useState, useEffect } from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthorizationError from './AuthorizationError';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const AddVendor = () => {

        const initialValues = {firstname: "", lastname: "",  phonenumber: "", email: "", address: ""};
        const [formValues, setFormValues] = useState(initialValues);
        const [formErrors, setFormErrors] = useState(initialValues);
        const [isSubmit, setIsSubmit] = useState(false);
        const [unauthorized, setUnauthorized] = useState(false);
        const baseURL = process.env.REACT_APP_BASE_URL;
        const [loading, setLoading] = useState(false); 

        const handleChange=(e)=>{
            const {name, value} = e.target;
            setFormValues({...formValues, [name]: value});     
        };
    
        const validate =(values)=>{
            const errors ={};
                 const regex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
                 const num =/(^07|01)\d{8}$/;
                if(!values.firstname){
                    errors.firstname ="Firstname is required!";
                }
                if(!values.lastname){
                    errors.lastname ="Lastname is required!";
                }
                if(!values.phonenumber){
                    errors.phonenumber ="Phonenumber is required!";
                }else if(!num.test(values.phonenumber)){
                    errors.phonenumber ="Phonenumber is NOT valid";
                }
                if(!values.email){
                    errors.email ="Email is required!";
                }else if (!regex.test(values.email)){
                    errors.email="This is not a valid email";
                }
                if(!values.address){
                    errors.address ="Address is required!";
                }
                return errors;     
            }
//............................................................................................................
        const saveVendor = (e) => {
            e.preventDefault();
            const errors = validate(formValues);
            setFormErrors(errors);
        
            if (Object.keys(errors).length === 0) {
                setIsSubmit(true); // Move this line here

                const token = sessionStorage.getItem("accessToken")
                setLoading(true);

                axios.post(`${baseURL}/api/vendor/addVendor`, formValues,{
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                      },
                })
                    .then(res => {
                        toast.success('New Vendor added successfully', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                        setFormValues(initialValues);

                        // setFormValues(initialValues);
                        // Simulate a delay of 2 seconds before hiding the loader
                    })
                    .catch(err => {
                        if (err.response.status === 403) {
                            setUnauthorized(true);
                          }
                        // toast.error('An error occurred while adding the record.', {
                        //     position: toast.POSITION.TOP_RIGHT,
                        //     autoClose: 3000,
                        // });
                    }).finally(() => {
                        setLoading(false); // Set loading state to false
                    });
            }
        };

        return (
          <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12 p-5 rounded shadow'>
            {unauthorized ? (
              <AuthorizationError />
            ) : (
              <div>
            {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

                  <Form onSubmit={saveVendor}>
            <h5 className='pb-4 display-12'> Add Workshop Vendor</h5>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Firstname:</Form.Label>
                    <Form.Control type="input" onChange={handleChange} autoComplete="off" name="firstname" 
                    value={formValues.firstname} placeholder="Enter Firstname" />
                    <p className='text-danger'>{formErrors.firstname}</p>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Lastname:</Form.Label>
                    <Form.Control type="input" onChange={handleChange} name="lastname" 
                    value={formValues.lastname} autoComplete="off" placeholder="Enter lastname" />
                    <p className='text-danger'>{formErrors.lastname}</p>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Phone Number:</Form.Label>
                    <Form.Control type="input" onChange={handleChange} name="phonenumber" 
                    value={formValues.phonenumber} autoComplete="off" placeholder="Enter Phone Number" />
                    <p className='text-danger'>{formErrors.phonenumber}</p>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control type="input" onChange={handleChange} name="email" 
                    value={formValues.email} autoComplete="off" placeholder="Enter Email" />
                    <p className='text-danger'>{formErrors.email}</p>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Physical Address:</Form.Label>
                    <Form.Control type="input" autoComplete="off" onChange={handleChange} name="address" 
                    value={formValues.address} placeholder="Enter Address" />
                    <p className='text-danger'>{formErrors.address}</p>
                </Form.Group>

                <Button variant="primary" type="submit">
                    Add Vendor
                </Button>
                <ToastContainer/>
               
            </Form>
              </div>
            )}
          </div>
        );
        
    // return (
    //     <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12 p-5 rounded shadow'>
            
    //         <Form onSubmit={saveVendor}>
    //         <h5 className='pb-4 display-12'> Add Workshop Vendor</h5>
    //             <Form.Group className="mb-3" controlId="formBasicEmail">
    //                 <Form.Label>Firstname:</Form.Label>
    //                 <Form.Control type="input" onChange={handleChange} autoComplete="off" name="firstname" 
    //                 value={formValues.firstname} placeholder="Enter Firstname" />
    //                 <p className='text-danger'>{formErrors.firstname}</p>
    //             </Form.Group>

    //             <Form.Group className="mb-3" controlId="formBasicEmail">
    //                 <Form.Label>Lastname:</Form.Label>
    //                 <Form.Control type="input" onChange={handleChange} name="lastname" 
    //                 value={formValues.lastname} autoComplete="off" placeholder="Enter lastname" />
    //                 <p className='text-danger'>{formErrors.lastname}</p>
    //             </Form.Group>

    //             <Form.Group className="mb-3" controlId="formBasicEmail">
    //                 <Form.Label>Phone Number:</Form.Label>
    //                 <Form.Control type="input" onChange={handleChange} name="phonenumber" 
    //                 value={formValues.phonenumber} autoComplete="off" placeholder="Enter Phone Number" />
    //                 <p className='text-danger'>{formErrors.phonenumber}</p>
    //             </Form.Group>

    //             <Form.Group className="mb-3" controlId="formBasicEmail">
    //                 <Form.Label>Email:</Form.Label>
    //                 <Form.Control type="input" onChange={handleChange} name="email" 
    //                 value={formValues.email} autoComplete="off" placeholder="Enter Email" />
    //                 <p className='text-danger'>{formErrors.email}</p>
    //             </Form.Group>

    //             <Form.Group className="mb-3" controlId="formBasicEmail">
    //                 <Form.Label>Physical Address:</Form.Label>
    //                 <Form.Control type="input" autoComplete="off" onChange={handleChange} name="address" 
    //                 value={formValues.address} placeholder="Enter Address" />
    //                 <p className='text-danger'>{formErrors.address}</p>
    //             </Form.Group>

    //             <Button variant="primary" type="submit">
    //                 Add Vendor
    //             </Button>
    //             <ToastContainer/>
    //         </Form>
    //     </div>
    // );
}
 
export default AddVendor;

