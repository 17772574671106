import { useEffect, useState } from "react";
import axios from 'axios';
import PaginationComponent from "./PaginationComponent";
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';
import { Col, Button, Form, Row, Container } from "react-bootstrap";

const ShowSaleInstallementBreakDown = () => {

    const [stocks, setStock] = useState([]);
    const [filteredStocks, setFilteredStocks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const itemsPerPage = 10; // Set the desired number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentData = stocks.slice(indexOfFirstItem, indexOfLastItem);
    const currentData = filteredStocks.slice(indexOfFirstItem, indexOfLastItem);
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearch = () => {
        const filteredData = stocks.filter(stock => 
            stock.customer.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredStocks(filteredData);
        setCurrentPage(1);
        setSearchQuery('');
        console.log("Filtered Data:", filteredData);
    };

    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.get(`${baseURL}/api/SaleInstallement/allMakeSale`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
        .then(res =>{setStock(res.data)
            // console.log(res.data);
            setFilteredStocks(res.data);
        })
        .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        })

    }, [])
    
    return ( 
        <div>
        {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

            <h5 className="mb-2"> Installement Break Down </h5>

            <div className="input-group mb-3">
                <input
                    type="text"
                    placeholder="Search by customer name"
                    name="searchQuery"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="form-control"
                />
                <button
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={handleSearch}
                >
                    Search
                </button>

                <button
                    className="btn btn-outline-success"
                    type="button"
                    onClick={handleSearch}
                >
                    Clear 
                </button>
            </div>

            <div className='table-responsive'> 
                <table className="table table-hover">
                    
                        <tr>
                            <th>Date Paid:</th>
                            <th>Customer Name:</th>
                            <th>AmountPaid:</th>
                        </tr>
                    
                    {/* <tbody>
                            {currentData.map((r, i)=>(
                                <tr key={i}>
                                <td>{new Date(r.createdAt).toDateString()}</td>
                                <td>{r.customer.customer_name}</td>
                                <td>{r.AmountPaid}</td>
                            </tr>
                            ))} 
                    </tbody> */}

                    <tbody>
                        {currentData.map((r, i) => (
                            <tr key={i}>
                                <td>{new Date(r.createdAt).toDateString()}</td>
                                <td>{r.customer.customer_name}</td>
                                <td>{r.AmountPaid}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <PaginationComponent
                currentPage={currentPage}
                totalItems={stocks.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />

        </div>
     );
}
 
export default ShowSaleInstallementBreakDown;