import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useState, useEffect } from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthorizationError from './AuthorizationError';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const WorkshopVendorPayment = () => {

    const initialValues = {firstname: "", lastname: "",  phonenumber: "", email: "", address: ""};
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState(initialValues);
    const [isSubmit, setIsSubmit] = useState(false);
    const [unauthorized, setUnauthorized] = useState(false)
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);

    const validate =(values)=>{
        const errors ={};
             const regex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
            if(!values.firstname){
                errors.firstname ="Firstname is required!";
            }
            if(!values.lastname){
                errors.lastname ="Lastname is required!";
            }
            if(!values.phonenumber){
                errors.phonenumber ="Phonenumber is required!";
            }
            if(!values.email){
                errors.email ="Email is required!";
            }else if (!regex.test(values.email)){
                errors.email="This is not a valid email";
            }
            if(!values.address){
                errors.address ="Address is required!";
            }
            return errors;     
    }

        const [materials, setMaterials] = useState([]);
        const [selectedMaterialId, setSelectedMaterialId] = useState('');
            useEffect(()=>{
                const token = sessionStorage.getItem("accessToken")
                
            axios.get(`${baseURL}/api/Material/allMaterial`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
            })
            .then(res =>{setMaterials(res.data)})
            
            .catch(err => console.log(err))
        }, [])

        const handleChange=(e)=>{
            const newSelectedMaterialId = e.target.value;
            setInfo({...info, material_id: newSelectedMaterialId})
            setSelectedMaterialId(newSelectedMaterialId);     
        };

        // code to bind vendor with drop down to get vendor id and name
        const [vendors, setVendors] = useState([]);
        const [selectedVendorId, setselectedVendorId] = useState('');
            useEffect(()=>{
                const token = sessionStorage.getItem("accessToken")

            axios.get(`${baseURL}/api/vendor/allVendors`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
            })
            .then(res =>{setVendors(res.data)})
            
            // .catch(err => console.log(err))
            .catch(err => {
                if (err.response.status === 403) {
                    setUnauthorized(true);
                  }
            })

        }, [])

        const handleDescription=(e)=>{
            setInfo({...info, [e.target.name]: e.target.value})
        };

        const handleAmount=(e)=>{
            setInfo({...info, [e.target.name]: e.target.value})
        };

        const handleVendor = (e) => {
            const newSelectedVendorId = e.target.value;
            setInfo({ ...info, vendor_id: newSelectedVendorId });
            setselectedVendorId(newSelectedVendorId);
          };

          const [info, setInfo]=useState({
            vendorname: "",
            materialSupplied: "",
            Description: "",
            AmountPaid: 0,
          })

          const Payment =(e)=>{
            e.preventDefault()
        
            const token = sessionStorage.getItem("accessToken")
            
            const requestData = {
              vendorname: selectedVendorId,
              materialSupplied: selectedMaterialId,
              Description: info.Description,
              AmountPaid: info.AmountPaid,
          };
          setLoading(true);

            axios.post(`${baseURL}/api/vendor/workshopVendorPayment`, requestData, {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
            })
                // .then(res=>console.log(requestData))
                .then(res =>{
                    toast.success('Payment Saved Successfully',{
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    })
                })
                // .catch(err=>console.log(err.message))    
                .catch(err=>{
                    if (err.response.status === 403) {
                        setUnauthorized(true);
                      }
                    // toast.error('An error occurred while adding the record.', {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 3000,
                    //   })
                }).finally(()=>{
                    setLoading(false);
                })     
          }


     return (
        <div>
                {unauthorized ? (
                    <AuthorizationError/>
                ) : (
            <>

            {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

            <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12 p-5 rounded shadow'>
            
            <Form onSubmit={Payment}>
            <h5 className='pb-4 display-12'> Workshop Vendor Payment</h5>
    
            <Form.Label>Vendor Name:</Form.Label>
                    <select className='form-control mb-3' name="vendor_id" value={selectedVendorId} onChange={handleVendor}>
                        <option value="">Select a Vendor</option>
                        {vendors.map(vendor => (
                        <option key={vendor.id} value={`${vendor.firstname} ${vendor.lastname}`}>
                            {`${vendor.firstname} ${vendor.lastname}`}
                        </option>
                        ))}
                    </select>
    
                 {/* code to bind material with drop down to get material id and name */}
                 <Form.Label>Material Supplied:</Form.Label>
                    <select className='form-control mb-3' name="material_id" value={selectedMaterialId} onChange={handleChange}>
                        <option value="">Select Material</option>
                        {materials.map(material => (
                        <option key={material.id} value={material.material_name}>
                             {material.material_name}
                        </option>
                        ))}
                    </select>
    
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Description:</Form.Label>
                    <Form.Control as="textarea" rows={3} required onChange={handleDescription} placeholder="More Description about payment" name="Description" />
                </Form.Group>
    
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Amount Paid:</Form.Label>
                    <Form.Control type="input" required onChange={handleAmount} placeholder="Amount paid" name="AmountPaid" />
                </Form.Group>
    
                <Button variant="primary" type="submit">
                    Pay Vendor
                </Button>
                <ToastContainer/>
             </Form>
             </div>
         </>
                )}
     </div>
     );
}
 
export default WorkshopVendorPayment;