import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useEffect } from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AuthorizationError from './AuthorizationError';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const Category = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const [unauthorized, setUnauthorized] = useState(false)
  const baseURL = process.env.REACT_APP_BASE_URL
  const initialValues = {customer_name:"", customer_mobileNo: ""};
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState(initialValues);

//   const [data, setData]=useState({
//     customer_name: "",
//     customer_mobileNo: "",
// })

const validate =(values)=>{
    const errors ={};

         const num =/(^07|01)\d{8}$/;
         if(!values.customer_name){
            errors.customer_name ="Name is required!";
        }
        if(!values.customer_mobileNo){
            errors.customer_mobileNo ="Phone number is required!";

        }else if(!num.test(values.customer_mobileNo)){
            errors.customer_mobileNo ="Phone number is NOT valid";
        }
        return errors;     
    }

// const handleChange =(e)=>{
//     setData({...data, [e.target.name]: e.target.value})
// }
const handleChange=(e)=>{
    const {name, value} = e.target;
    setFormValues({...formValues, [name]: value});     
};
const [loading, setLoading] = useState(false); 

const saveCustomer = (e)=>{
    e.preventDefault()
    const errors = validate(formValues);

    setFormErrors(errors);
if (Object.keys(errors).length === 0) {
    const token = sessionStorage.getItem("accessToken")

    setLoading(true); 

    axios.post(`${baseURL}/api/Customers/addCustomer`, formValues,{
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
    })
        // .then(res=>console.log(res))
        .then(res =>{
            
            // Update the list of records after successfully adding a new customer
            setRecords([...records, res.data]);

            toast.success('New Customer added successfully',{
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            })
        })

        // .catch(err=>console.log(err.message))    
        .catch(err=>{
            toast.error('An error occurred while adding the record.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              })
        }).finally(()=>{
            setLoading(false); 
        }) 
    }    
}

const [records, setRecords]= useState([])
    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")

        setLoading(true); 

        axios.get(`${baseURL}/api/Customers/allCustomers`,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
        .then(res =>{setRecords(res.data)})
        .catch(err => {
            if (err.response.status === 403) {
                setUnauthorized(true);
              }
        }).finally(()=>{
            setLoading(false); 
        })
    }, [])

    const loadEdit=(id)=>{
        // navigate("/api/measurement/" + id);
        navigate("/UpdateCustomer/" + id);
    }


    return (
        <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12 p-4'>
          {unauthorized ? (
            <AuthorizationError />
          ) : (
            <div>
             {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

            <Button variant="success" type="submit" onClick={handleShow} className='mb-3 btn-sm'>
                    ADD CUSTOMER
            </Button>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
            
            <Form onSubmit={saveCustomer}>   
                <Modal.Header closeButton>
                {/* <h6>add new Category</h6> */}
                <Modal.Title><h6>Add new customer</h6></Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                    
                        <Form.Group className="mb-3" controlId="customer_name">
                        <Form.Label>Customer Name:</Form.Label>
                        <Form.Control
                            type="input"
                            placeholder="Enter Customer fullname"
                             onChange={handleChange}
                            value={formValues.customer_name}
                            name="customer_name"
                            autoFocus
                        />
                        <p className='text-danger'>{formErrors.customer_name}</p>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="customer_mobileNo">
                        <Form.Label>Mobile No:</Form.Label>
                        <Form.Control
                            type="input"
                            placeholder="Enter Mobile Number"
                            onChange={handleChange}
                            value={formValues.customer_mobileNo}
                            name="customer_mobileNo"
                            autoFocus
                            
                        />
                        <p className='text-danger'>{formErrors.customer_mobileNo}</p>
                        </Form.Group>
                                    
                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type='submit'>
                        Add Customer
                    </Button>
                    <ToastContainer/>
                </Modal.Footer>
                </Form>
            </Modal>

            <div className='table-responsive'>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Customer Name</th>
                            <th>Mobile No:</th>
                            <th>Action</th>
                        </tr>
                    </thead> 
                    <tbody>
                        {records.map((r, i)=>(
                            <tr key={i}>
                            {/* <td>{r.customer_id}</td> */}
                            <td>{r.customer_name}</td>
                            <td>{r.customer_mobileNo}</td>
                            <td>
                                <Dropdown>
                                     <Dropdown.Toggle variant="default" id="dropdown-basic" size="sm">
                                            Perform Actions
                                     </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Link to={`/UpdateCustomer/${r.customer_id}`} className="dropdown-item">
                                            Edit  Customer
                                            </Link>
                                        </Dropdown.Menu>
                                 </Dropdown>
                            </td>
                            </tr>
                         ))}
                    </tbody>
                </table>
            </div>
        </div>
          )}
        </div>
      );
}
 
export default Category;