import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useState, useEffect } from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthorizationError from './AuthorizationError';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const ReceiveNewStock = () => {
    
        // const validate =(values)=>{
        //     const errors ={};
        //          const regex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        //         if(!values.firstname){
        //             errors.firstname ="Firstname is required!";
        //         }
        //         if(!values.lastname){
        //             errors.lastname ="Lastname is required!";
        //         }
        //         if(!values.phonenumber){
        //             errors.phonenumber ="Phonenumber is required!";
        //         }
        //         if(!values.email){
        //             errors.email ="Email is required!";
        //         }else if (!regex.test(values.email)){
        //             errors.email="This is not a valid email";
        //         }
        //         if(!values.address){
        //             errors.address ="Address is required!";
        //         }
        //         return errors;     
        // }
//............................................................................................................
        // const saveVendor = (e) => {
        //     e.preventDefault();
        //     const errors = validate(formValues);
        //     setFormErrors(errors);
        
            // if (Object.keys(errors).length === 0) {
            //     setIsSubmit(true); // Move this line here
        
            //     axios.post('http://localhost:4000/api/vendor/addVendor', formValues)
            //         .then(res => {
            //             toast.success('New Vendor added successfully', {
            //                 position: toast.POSITION.TOP_RIGHT,
            //                 autoClose: 3000,
            //             });
            //             setFormValues(initialValues);
            //         })
            //         .catch(err => {
            //             toast.error('An error occurred while adding the record.', {
            //                 position: toast.POSITION.TOP_RIGHT,
            //                 autoClose: 3000,
            //             });
            //         });
            // }
        //};
        
        // code to bind material with drop down to get material id and name
        const [materials, setMaterials] = useState([]);
        const [selectedMaterialId, setSelectedMaterialId] = useState('');
        const [unauthorized, setUnauthorized] = useState(false);
        const baseURL = process.env.REACT_APP_BASE_URL
        const [loading, setLoading] = useState(false);

            useEffect(()=>{
                const token = sessionStorage.getItem("accessToken")

              setLoading(true);

            axios.get(`${baseURL}/api/Material/allMaterial`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
            })
            .then(res =>{setMaterials(res.data)})
            
            .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        })
        }, [])

        const handleChange=(e)=>{
            const newSelectedMaterialId = e.target.value;
            setInfo({...info, material_id: newSelectedMaterialId})
            setSelectedMaterialId(newSelectedMaterialId);     
        };


        // code to bind vendor with drop down to get vendor id and name
        const [vendors, setVendors] = useState([]);
        const [selectedVendorId, setselectedVendorId] = useState('');
            useEffect(()=>{
                const token = sessionStorage.getItem("accessToken")
            axios.get(`${baseURL}/api/vendor/allVendors`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
            })
            .then(res =>{setVendors(res.data)})
            
            .catch(err => {
                if (err.response.status === 403) {
                    setUnauthorized(true);
                }
            })
        }, [])

        const handleQuantity=(e)=>{
            setInfo({...info, [e.target.name]: e.target.value})
        };

        const handleVendor = (e) => {
            const newSelectedVendorId = e.target.value;
            setInfo({ ...info, vendor_id: newSelectedVendorId });
            setselectedVendorId(newSelectedVendorId);
          };


        const [info, setInfo]=useState({
            material_id: 0,
            QuantityReceived: 0,
            vendor_id: 0,
          })
          
          const ReceiveProduct =(e)=>{
              e.preventDefault()
          
              const token = sessionStorage.getItem("accessToken")
              const requestData = { ...info };

              setLoading(true);

              axios.post(`${baseURL}/api/receiveStock/addStock`, requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
              })
                  // .then(res=>console.log(requestData))
                  .then(res =>{
                      toast.success('Stock Received Successfull',{
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 3000,
                      })
                  })
                  // .catch(err=>console.log(err.message))    
                  .catch(err=>{
                    if (err.response.status === 403) {
                        setUnauthorized(true);
                    }
                  }).finally(()=>{
                    setLoading(false);
                })      
            }
    return (
        <div className="container-fluid">
     {unauthorized ? (
        <AuthorizationError />
     ) : (

     <>
     <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12 p-5 rounded shadow'>
           
           {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />} 

            <Form onSubmit={ReceiveProduct}>
            <h5 className='pb-3 display-12'> Receive New Stock</h5>

            {/* code to bind material with drop down to get material id and name */}
            <Form.Label>Material Name:</Form.Label>
                <select className='form-control mb-3' name="material_id" value={selectedMaterialId} onChange={handleChange}>
                    <option value="">Select Material</option>
                    {materials.map(material => (
                    <option key={material.id} value={material.material_id}>
                        {`${material.material_id} - ${material.material_name}`}
                    </option>
                    ))}
                </select>
         
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Quantity Received:</Form.Label>
                    <Form.Control type="input" onChange={handleQuantity} name="QuantityReceived" />
                </Form.Group>

                {/*bind vendor with drop down to get vendor id and name */}
                <Form.Label>Vendor Name:</Form.Label>
                <select className='form-control mb-3' name="vendor_id" value={selectedVendorId} onChange={handleVendor}>
                    <option value="">Select a Vendor</option>
                    {vendors.map(vendor => (
                    <option key={vendor.id} value={vendor.vendor_id}>
                        {`${vendor.vendor_id} - ${vendor.firstname}  ${vendor.lastname}`}
                    </option>
                    ))}
                </select>

                <Button variant="primary" type="submit">
                    Receive Stock
                </Button>
                <ToastContainer/>
            </Form>
        </div>
        
     </>

    )}
    </div>
    )   
}
 
export default ReceiveNewStock;

