import React, { useState } from 'react'
import axios from 'axios'
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../../spinnerStyles';
import { Link } from 'react-router-dom';

export default function ForgotPassword() {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateEmail(email)) {
            setMessage('Please enter a valid email address.');
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(`${baseURL}/api/auth/forgot-password`, { email });

            setMessage(response.data.message);
        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.message);
            } else {
                console.error('Error:', error);
                setMessage('An error occurred. Please try again later.');
            }
        }finally{
            setLoading(false);
        }
    };

    const validateEmail = (email) => {
        //email validation
        const re = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        return re.test(email);
    };

  return (
    <>
        <div class="container">

{/* <!-- Outer Row --> */}
<div class="row justify-content-center">

    <div class="col-xl-10 col-lg-12 col-md-8">

        <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
                {/* <!-- Nested Row within Card Body --> */}
                <div class="row">
                    {/* <div class="col-lg-6 d-none d-lg-block bg-password-image"></div> */}
                    <div class="col-lg-12">
                        <div class="p-5">
                            
                            {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
                            <div class="text-center">
                            
                                <h2 class="h4 text-gray-900 mb-2">Forgot Your Password?</h2>
                                <p class="mb-4">We get it, stuff happens. Just enter your email address below
                                    and we'll send you a link to reset your password!</p>
                            </div>
                            <form onSubmit={handleSubmit} class="user">
                            {/* <form class="user"> */}
                                <div class="form-group">
                                    <input type="email" class="form-control"
                                        id="exampleInputEmail" aria-describedby="emailHelp"
                                        placeholder="Enter Email Address..."onChange={(e) => setEmail(e.target.value)}
                                        required autoComplete="off" />
                                </div>
                                <button className='btn btn-primary'> Reset Password </button>
                                <Link to='/login' className="collapse-item" style={{float: 'right', textDecoration: 'none'}} >Back to Login</Link>                              
                            </form>

                            {message && <p>{message}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

</div>
    </>
  )
}
