import React from 'react'
import { Link } from 'react-router-dom'

export default function Sidebar() {
  return (
 
    <React.Fragment>
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
        
{/* <!-- Sidebar - Brand --> */}
<a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
    <div className="sidebar-brand-icon rotate-n-15">
        {/* <i className="fas fa-laugh-wink"></i> */}
    </div>
    <div className="sidebar-brand-text mx-3">Matan Furnitures ltd </div>
</a>

{/* <!-- Divider --> */}
<hr className="sidebar-divider my-0"/>

{/* <!-- Nav Item - Dashboard --> */}
<li className="nav-item active">
    <Link to='/Dashboard' className="nav-link" >
        <i className="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span></Link>
</li>

{/* <!-- Divider --> */}
<hr className="sidebar-divider"/>

{/* <!-- Heading --> */}
<div className="sidebar-heading">
    workshop
</div>

{/* <!-- Nav Item - Pages Collapse Menu --> */}
<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
        aria-expanded="true" aria-controls="collapseTwo">
        <i className="fas fa-fw fa-users"></i>
        <span>Workshop-Vendors</span>
    </a>
    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">vendors:</h6>
            <Link to='/AddVendor' className="collapse-item" ><span>Add Vendor</span></Link>
            <Link to='/AllVendors' className="collapse-item" >All Vendors</Link>
            <Link to='/WorkshopVendorPayment' className="collapse-item" >Vendor Payment</Link>
            <Link to='/AllWorkshopVendorPayment' className="collapse-item" >Show Payment</Link>
        </div>
    </div>
</li>

{/* <!-- Nav Item - Utilities Collapse Menu --> */}
<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
        aria-expanded="true" aria-controls="collapseUtilities">
        <i className="fas fa-fw fa-tag"></i>
        <span>Products</span>
    </a>
    <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
        data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">products:</h6>
            <Link className="collapse-item" to='/CreateProduct'>Create Product</Link>
            <Link className="collapse-item" to='/Material'>Raw Materials</Link>
            <Link className="collapse-item" to='/RawMaterialUsage'>Raw Material Usage</Link>
            <Link className="collapse-item" to='/UnitOfMeasurement'>Unit of Measurement</Link>
            {/* <Link className="collapse-item" to='/Category'>Categories</Link> */}
        </div>
    </div>
</li>

<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseStock"
        aria-expanded="true" aria-controls="collapseStock">
        <i className="fas fa-fw fa-hourglass"></i>
        <span>Stock</span>
    </a>
    <div id="collapseStock" className="collapse" aria-labelledby="headingUtilities"
        data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">stock:</h6>
            <Link className="collapse-item" to='/ReceiveNewStock'>Receive Stock</Link>
            <Link className="collapse-item" to='/ShowReceivedStock'>Show received Stock</Link>
            <Link className="collapse-item" to='/Stock'>Available Stock</Link>
        </div>
    </div>
</li>

<hr className="sidebar-divider"/>

<div className="sidebar-heading">
    Store
</div>

<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseVendor"
        aria-expanded="true" aria-controls="collapseVendor">
        <i className="fas fa-fw fa-users"></i>
        <span>Store-Vendors</span>
    </a>
    <div id="collapseVendor" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">vendors:</h6>
            <Link to='/StoreVendor' className="collapse-item" ><span>Add Vendor</span></Link>
            <Link to='/AllStoreVendors' className="collapse-item" >All Vendors</Link>
            <Link to='/StoreVendorPayment' className="collapse-item" >Vendor Payment</Link>
            <Link to='/AllStoreVendorPayment' className="collapse-item" >Show Payment</Link>
        </div>
    </div>
</li>

<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities1"
        aria-expanded="true" aria-controls="collapseUtilities1">
        <i className="fas fa-fw fa-tag"></i>
        <span>Products</span>
    </a>
    <div id="collapseUtilities1" className="collapse" aria-labelledby="headingUtilities"
        data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">products:</h6>
            <Link className="collapse-item" to='/Product'>Ready Made Product</Link>
            <Link className="collapse-item" to='/ReceiveProduct'>Receive Product</Link>
            <Link className="collapse-item" to='/ShowReceiveProduct'>Show Received Product</Link>
        </div>
    </div>
</li>

<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseStore"
        aria-expanded="true" aria-controls="collapseStore">
        <i className="fas fa-fw fa-store"></i>
        <span>Store</span>
    </a>
    <div id="collapseStore" className="collapse" aria-labelledby="headingUtilities"
        data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">store:</h6>
            <Link className="collapse-item" to='/Store'>Check Store</Link>
        </div>
    </div>
</li>

{/* <!-- Divider --> */}
<hr className="sidebar-divider"/>

<div className="sidebar-heading">
    customers
</div>

<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCustomer"
        aria-expanded="true" aria-controls="collapseCustomer">
        <i className="fas fa-fw fa-users"></i>
        <span>Customers</span>
    </a>
    <div id="collapseCustomer" className="collapse" aria-labelledby="headingUtilities"
        data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Customers:</h6>
            <Link className="collapse-item" to='/AddCustomer'>Customers</Link>
            <Link to='/MakeSaleInstallment' className="collapse-item" ><span>Customer-Installments</span></Link>
            <Link to='/ShowSaleInstallement' className="collapse-item" ><span>Show Installments</span></Link>
        </div>
    </div>
</li>


{/* <!-- Divider --> */}
<hr className="sidebar-divider"/>

<div className="sidebar-heading">
    Stores - produced products
</div>

<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#Matan1"
        aria-expanded="true" aria-controls="collapseMatan1">
        <i className="fas fa-fw fa-store"></i>
        <span>Matan One</span>
    </a>
    <div id="Matan1" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Matan one:</h6>
            <Link to='/MakeSale1Produced' className="collapse-item" ><span>Make sale</span></Link>
            {/* <Link to='/MakeSaleInstallment' className="collapse-item" ><span>Make sale </span></Link> */}
            {/* <Link to='/ShowSaleInstallement' className="collapse-item" ><span>Show Installments</span></Link> */}
            <Link to='/MatanOneSalesProduced' className="collapse-item" >Sales Made</Link>
            <Link to='/MatanOneStockProduced' className="collapse-item" >Available Stock</Link>
            <Link to='/MatanReport' className="collapse-item" >Sales Report</Link>   
        </div>
    </div>
</li>

<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#Matan2"
        aria-expanded="true" aria-controls="collapseMatan2">
        <i className="fas fa-fw fa-store"></i>
        <span>Matan Two</span>
    </a>
    <div id="Matan2" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">matan two:</h6>
            <Link to='/MakeSale2Produced' className="collapse-item" ><span>Make sale</span></Link>
            <Link to='/MatanTwoSalesProduced' className="collapse-item" >Sales Made</Link>
            <Link to='/MatanTwoStockProduced' className="collapse-item" >Available Stock</Link>
            <Link to='/Matan2Report' className="collapse-item" >Sales Report</Link>   
        </div>
    </div>
</li>

<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#Matan3"
        aria-expanded="true" aria-controls="collapseMatan3">
        <i className="fas fa-fw fa-store"></i>
        <span>Matan Three</span>
    </a>
    <div id="Matan3" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">matan three:</h6>
            <Link to='/MakeSale3Produced' className="collapse-item" ><span>Make sale</span></Link>
            <Link to='/MatanThreeSalesProduced' className="collapse-item" >Sales Made</Link>
            <Link to='/MatanThreeStockProduced' className="collapse-item" >Available Stock</Link>
            <Link to='/Matan3Report' className="collapse-item" >Sales Report</Link>   
        </div>
    </div>
</li>
<hr className="sidebar-divider"/>

{/* .................................... */}

<div className="sidebar-heading">
    Stores - ready-made products
</div>

<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseMatan1"
        aria-expanded="true" aria-controls="collapseMatan1">
        <i className="fas fa-fw fa-users"></i>
        <span>Matan One</span>
    </a>
    <div id="collapseMatan1" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Matan one:</h6>
            <Link to='/MakeSale1' className="collapse-item" ><span>Make Sale</span></Link>
            <Link to='/MatanOneSales' className="collapse-item" >Sales Made</Link>
            <Link to='/MatanOneStock' className="collapse-item" >Available Stock</Link>
            <Link to='/Matan1SalesReport' className="collapse-item" >Sales Report</Link>
            
        </div>
    </div>
</li>

<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseMatan2"
        aria-expanded="true" aria-controls="collapseMatan2">
        <i className="fas fa-fw fa-users"></i>
        <span>Matan Two</span>
    </a>
    <div id="collapseMatan2" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">matan two:</h6>
            <Link to='/MakeSale2' className="collapse-item" ><span>Make sale</span></Link>
            <Link to='/MatanTwoSales' className="collapse-item" >Sales Made</Link>
            <Link to='/MatanTwoStock' className="collapse-item" >Available Stock</Link>
            <Link to='/Matan2SalesReport' className="collapse-item" >Sales Report</Link>
        </div>
    </div>
</li>

<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseMatan3"
        aria-expanded="true" aria-controls="collapseMatan3">
        <i className="fas fa-fw fa-users"></i>
        <span>Matan Three</span>
    </a>
    <div id="collapseMatan3" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">matan three:</h6>
            <Link to='/MakeSale3' className="collapse-item" ><span>Make sale</span></Link>
            <Link to='/MatanThreeSales' className="collapse-item" >Sales Made</Link>
            <Link to='/MatanThreeStock' className="collapse-item" >Available Stock</Link>
            <Link to='/Matan3SalesReport' className="collapse-item" >Sales Report</Link>
        </div>
    </div>
</li>


<hr className='sidebar-divider'/>

{/* <!-- Heading --> */}
<div className="sidebar-heading">
    manage users
</div>

{/* <!-- Nav Item - Pages Collapse Menu --> */}
<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
        aria-expanded="true" aria-controls="collapsePages">
        <i className="fas fa-fw fa-users"></i>
        <span>Users</span>
    </a>
    <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">users manage:</h6>
            <Link className="collapse-item" to='/createUser'>Create User</Link>
            {/* <Link className="collapse-item" to='/register'>All Users</Link> */}
            <Link className="collapse-item" to='/UserManagement'>User Management</Link>
            <div className="collapse-divider"></div>
            <h6 className="collapse-header">Others:</h6>
            <Link className="collapse-item" to="/error">404 Page</Link>
            <Link className="collapse-item" to="/blank">Blank Page</Link>
        </div>
    </div>
</li>

{/* <!-- Nav Item - Charts --> */}
{/* <li className="nav-item">
    <Link className="nav-link" to='/charts'>
        <i className="fas fa-fw fa-chart-area"></i>
        <span>Charts</span></Link>
</li> */}

{/* <!-- Nav Item - Tables --> */}
{/* <li className="nav-item">
    <Link className="nav-link" to='/tables'>
        <i className="fas fa-fw fa-table"></i>
        <span>Tables</span></Link>
</li> */}

{/* <!-- Divider --> */}
<hr className="sidebar-divider d-none d-md-block"/>

{/* <!-- Sidebar Toggler (Sidebar) --> */}
<div className="text-center d-none d-md-inline">
    <button className="rounded-circle border-0" id="sidebarToggle"></button>
</div>

</ul>
    </React.Fragment>
   
  )
}
