import React from 'react'

export default function Blank() {
  return (
    <>
        
        {/* <!-- Page Heading --> */}
                    <h1 class="h3 mb-4 text-gray-800">Blank Page</h1>
    </>
  )
}
