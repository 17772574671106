import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const UpdateVendor = () => {
    const { vendor_id } = useParams();
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.get(`${baseURL}/api/vendor/` + vendor_id,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        }) 
          .then(res => {
            setData({
              vendor_id: res.data.vendor_id,
              firstname: res.data.firstname,
              lastname: res.data.lastname,
              phonenumber: res.data.phonenumber,
              email: res.data.email,
              address: res.data.address
            });
          })
          .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        });
      }, [vendor_id]);  

      const [data, setData]=useState({
            firstname: "",
            lastname: "",
            phonenumber: "",
            email: "",
            address: ""
        })
     const navigate = useNavigate();

    const handleChange =(e)=>{
        setData({...data, [e.target.name]: e.target.value})
    }
//..................................................... 
    const saveVendor = (e)=>{
        e.preventDefault()

        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.patch(`${baseURL}/api/vendor/${vendor_id}`, data,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
            // .then(res=>console.log(res))
            .then(res =>{
                // toast.success('Vendor updated successfully',{
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 3000,
                // })
                alert("vendor updated successfully")
                navigate("/AllVendors");
            })
            // .catch(err=>console.log(err.message))    
            .catch(err=>{
                toast.error('An error occurred while updating the record.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  })
            }).finally(()=>{
                setLoading(false);
            })      
    }
    return (
    <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12 p-5 rounded shadow'>
         
         {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

            <Form onSubmit={saveVendor}>
            <h5 className='pb-1 display-12'> Edit Vendor</h5>

                 <Form.Group className="mb-3" controlId="unit_Id">
                     {/* <Form.Label>Vendor Id:</Form.Label> */}
                        <Form.Control type="hidden" required onChange={handleChange}
                        value={data.vendor_id} name="vendor_id" disabled="disabled"/>
                 </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Firstname:</Form.Label>
                    <Form.Control type="input" required onChange={handleChange} name="firstname" 
                     value={data.firstname}placeholder="Enter Firstname" />
                    {/* <p className='text-danger'>{formErrors.firstname}</p> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Lastname:</Form.Label>
                    <Form.Control type="input" required onChange={handleChange} name="lastname" 
                      value={data.lastname}placeholder="Enter lastname" />
                    {/* <p className='text-danger'>{formErrors.lastname}</p> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Phone Number:</Form.Label>
                    <Form.Control type="input" required onChange={handleChange} name="phonenumber" 
                     value={data.phonenumber} placeholder="Enter Phone Number" />
                    {/* <p className='text-danger'>{formErrors.phonenumber}</p> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control type="input" required onChange={handleChange} name="email" 
                     value={data.email} placeholder="Enter Email" />
                    {/* <p className='text-danger'>{formErrors.email}</p> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Physical Address:</Form.Label>
                    <Form.Control type="input" required onChange={handleChange} name="address" 
                     value={data.address} placeholder="Enter Address" />
                    {/* <p className='text-danger'>{formErrors.address}</p> */}
                </Form.Group>

                <Button variant="primary" type="submit">
                    Update Vendor
                </Button>
                <ToastContainer/>
            </Form>
        </div>
    );
      
}
 
export default UpdateVendor;


