import { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const DeleteMaterial = () => {
    const { material_id } = useParams();
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);

    const [data, setData]=useState({
      material_name: ""
})

    useEffect(() => {
      const token = sessionStorage.getItem("accessToken")
      setLoading(true);

      setLoading(true);

        axios.get(`${baseURL}/api/Material/` + material_id,{
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }) 
          .then(res => {
            setData({
              material_id: res.data.material_id,
              material_name: res.data.material_name 
            });
          })
          .catch(err => console.log(err)).finally(()=>{ setLoading(false);
        })
;
      }, [material_id]);  

    
    return (
        <div >
          {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
          
            <h4 className="text-danger pb-4 display-12">Are you Sure you want to Delete the Record Below ?</h4>

                     <h5 className="pb-4 display-12">  Material Id: {data.material_id}  </h5>
                     
                     <h5 className="pb-4 display-12" >  Material Name: {data.material_name} </h5>                  
                  
                     <hr></hr>
                     <Button variant="danger" type="submit" className="mr-3">
                    yes delete 
                    </Button> 

                    <Button variant="light" type="submit" className="mr-3">
                    no take me back
                    </Button> 
        </div>
     );
}
 
export default DeleteMaterial;