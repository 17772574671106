import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import PaginationComponent from './PaginationComponent';
import { Dropdown } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import AuthorizationError from "./AuthorizationError";
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const ShowSuppliers = () => {

    const [records, setRecords]= useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Set the desired number of items per page
    const [unauthorized, setUnauthorized] = useState(false);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = records.slice(indexOfFirstItem, indexOfLastItem);
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false); 

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")

        setLoading(true); 
        
        axios.get(`${baseURL}/api/StoreVendor/allStoreVendors`,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
        .then(res =>{setRecords(res.data)})
        // .catch(err => console.log(err))
        .catch(err=>{
            if (err.response.status === 403) {
                setUnauthorized(true);
              }
        }).finally(()=>{
            setLoading(false); 
        })
    }, [])

    const navigate = useNavigate();

    const loadEdit=(id)=>{
        // navigate("/api/measurement/" + id);
        navigate("/UpdateStoreVendor/" + id);
    }

    const LoadVendor=(id)=>{
        // navigate("/api/measurement/" + id);
        navigate("/StoreVendorDetails/" + id);
        
    }

    const LoadDelete=(id)=>{
        // navigate("/api/measurement/" + id);
        navigate("/DeleteStoreVendor/" + id);
    }

    return (
        <div>
            {unauthorized ? (
                <AuthorizationError />
            ) : (
                <>
                {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
                    
        <h5> All Store Vendors</h5>
     <div className="table-responsive">
        <table className="table table-bordered table-md">
                    
                <thead>
                    <tr>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Physical Address</th>
                        <th>Action</th>
                    </tr>
                </thead> 
                <tbody>
                    {currentData.map((r, i)=>(
                        <tr key={i}>
                        <td>{r.firstname}</td>
                        <td>{r.lastname}</td>
                        <td>{r.phonenumber}</td>
                        <td>{r.email}</td>
                        <td>{r.address}</td>
                        <td>
                        <Dropdown>
                                <Dropdown.Toggle variant="default" id="dropdown-basic" size="md">
                                    Perform Actions
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Link to="/action-1" className="dropdown-item" onClick={(e) => { e.preventDefault(); LoadVendor(r.vendor_id)}}>
                                    Details
                                    </Link>
                                    <Link to="/UpdateUser" className="dropdown-item" onClick={(e) => { e.preventDefault(); loadEdit(r.vendor_id)}} >
                                    Edit Vendor
                                    </Link>
                                    {/* <Link to="/delete" className="dropdown-item" onClick={(e) => { e.preventDefault(); LoadDelete(r.vendor_id)}}>
                                    Delete
                                    </Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            
                        </td>
                    </tr>
                    ))}
                    <ToastContainer/>
                </tbody>
                
         </table>
     </div>             
                    <PaginationComponent
                        currentPage={currentPage}
                        totalItems={records.length}
                        itemsPerPage={itemsPerPage}
                        onPageChange={handlePageChange}
                    />
                </>
            )}
        </div>
    );

    // return (
    //     <div>
    //         <h5> All Store Vendors</h5>
            

    //         <PaginationComponent
    //             currentPage={currentPage}
    //             totalItems={records.length}
    //             itemsPerPage={itemsPerPage}
    //             onPageChange={handlePageChange}
    //         />

    //     </div>
    // );
}
 
export default ShowSuppliers;

// onClick={()=>loadEdit(r.unit_id)}