import React, { useState } from 'react';
import { Form, Button} from 'react-bootstrap';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';


const CreateUser = () => {
  const initialValues = { email: '', password: '', confirmpassword: '' };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState(initialValues);
  // const [isSubmit, setIsSubmit] = useState(false);

  const baseURL = process.env.REACT_APP_BASE_URL
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    // setIsSubmit(true);

    try {
      const token = sessionStorage.getItem("accessToken");

      // Check if passwords match before sending data to the server
    if (formValues.password !== formValues.confirmpassword) {
      toast.error('Passwords do not match', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }

      // Remove the 'confirmpassword' property before sending data to the server
      const { confirmpassword, ...formDataWithoutConfirmPassword } = formValues;
        
      setLoading(true);

        await axios.post(`${baseURL}/api/auth/register`, formDataWithoutConfirmPassword, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // const successMessage = response.data.message;

      // toast.success(successMessage);

      toast.success("New User Added Successfull", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
    })

    } catch (error) {
    
    if (error.response && error.response.status === 409 || error.response.data && error.response.data.message) {
      // Display the specific error message to the user
      toast.error("The username is already in use", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      // If there's no specific error message or if the structure doesn't match, display a generic error
      console.error('Error during registration:', error);
      toast.error(`An error occurred while adding a user.`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
} finally {
  setLoading(false);
}

  };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Username is required!';
    }
    if (!values.password) {
      errors.password = 'Password is required!';
    }
   
    if (formErrors.email) {
      errors.email = formErrors.email;
    }
    if (formErrors.password) {
      errors.password = formErrors.password;
    }
    return errors;
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      
      
      <div className="col-md-12">
      {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

        <Form onSubmit={handleSubmit} className="p-5 rounded shadow">
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4 class="text-left pb-4 display-6">Create new user</h4>
                 <Form.Label>Email address:</Form.Label>
                 <Form.Control type="email" placeholder="Enter username" 
                  name="email" value={formValues.email}
                  onChange={handleChange} />
                  <p className='text-danger'>{formErrors.email}</p>
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicPassword">
                <Form.Label>Password:</Form.Label>
                <Form.Control type="password" placeholder="Password"
                name="password" value={formValues.password}
                onChange={handleChange}/>
                <p className='text-danger'>{formErrors.password}</p>
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicPassword">
                <Form.Label>Confirm-Password:</Form.Label>
                <Form.Control type="password" placeholder="Confirm-Password"
                name="confirmpassword" value={formValues.confirmpassword}
                onChange={handleChange}/>
                <p className='text-danger'>{formErrors.confirmpassword}</p>
            </Form.Group>
            
            <Button variant="primary" type="submit" className="mb-4">
                  Create User
            </Button> 
            <ToastContainer/>
          </Form>
      </div>
    </div>
  );
};

export default CreateUser;