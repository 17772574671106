import React, { useState } from 'react'
import axios from 'axios'
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../../spinnerStyles';
import { useParams } from 'react-router-dom';

export default function ResetPassword() {

    const [message, setMessage] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);
    const { token } = useParams();
    

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        try {
            setLoading(true);
            const response = await axios.patch(`${baseURL}/api/auth/resetPassword/${token}`, {
                newPassword
            });

            setMessage(response.data.message);
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.message);
            } else {
                console.error('Error:', error);
                setMessage('An error occurred. Please try again later.');
            }
        }finally{
            setLoading(false);
        }
    };

  return (
    <>
        <div class="container">

{/* <!-- Outer Row --> */}
<div class="row justify-content-center">

    <div class="col-xl-10 col-lg-12 col-md-8">

        <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
                {/* <!-- Nested Row within Card Body --> */}
                <div class="row">
                    {/* <div class="col-lg-6 d-none d-lg-block bg-password-image"></div> */}
                    <div class="col-lg-12">
                        <div class="p-5">
                            <div class="text-center">

                                {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

                                <h4 class="h4 text-gray-900 mb-3">Reset password here</h4>
                            </div>
                            <form onSubmit={handleSubmit} class="user">
                                <div class="form-group">
                                    <input type="password" class="form-control"
                                        id="newPassword" aria-describedby="emailHelp"
                                        value={newPassword}
                                        placeholder="Enter Password..."onChange={(e) => setNewPassword(e.target.value)}
                                        required />
                                </div>

                                <div class="form-group">
                                    <input type="password" class="form-control"
                                        id="confirmPassword" aria-describedby="emailHelp"
                                        value={confirmPassword}
                                        placeholder="Confirm Password..."onChange={(e) => setConfirmPassword(e.target.value)}
                                        required/>
                                </div>

                                <button className='btn btn-primary'> Change Password </button>
                            </form>

                            {message && <p>{message}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

</div>
    </>
  )
}
