import { useEffect, useState } from "react";
import axios from 'axios';
import AuthorizationError from "./AuthorizationError";
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const ShowStock = () => {
    const [materials, setMaterials] = useState([]);
    const [unauthorized, setUnauthorized] = useState(false);
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.get(`${baseURL}/api/materialInventory/allMaterials`,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
        .then(res =>{setMaterials(res.data)})
        .catch(err => {
            if (err.response.status === 403) {
                setUnauthorized(true);
              }
        }).finally(()=>{
            setLoading(false);
        })
    }, [])

    return (
        <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12'>
          {unauthorized ? (
            <AuthorizationError />
          ) : (
            <div>

          {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

            <h5 className="mb-3"> stock in Store</h5>
            <table className="table table-stripped">
                <tr>
                    <th>Material Name</th>
                    <th>Quantity In Stock</th>
                </tr>
                <tbody>
                        {materials.map((r, i)=>(
                            <tr key={i}>
                            <td>{r.material_name}</td>
                            <td>{r.MaterialInventories.length > 0 ? (r.MaterialInventories[0].quantity ) : ('N/A')}</td> 
                        </tr>
                        ))}
                    
                    </tbody>
                
            </table>
        </div>
          )}
        </div>
      );
 }
 
export default ShowStock;
