import { useEffect, useState } from "react";
import axios from 'axios';
import PaginationComponent from "./PaginationComponent";
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const ShowMatanThreeSalesProduced = () => {

    const [sales, setSales] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Set the desired number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = sales.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);


    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.get(`${baseURL}/api/ProductionSale/allSales3`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              }, 
        })
        .then(res =>{setSales(res.data)
            console.log(res.data);
        })
        .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        })
    }, [])

    
    return ( 
        <div>
            
            {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

            <h5 className="mb-3"> Matan Three Sales Made - produced products</h5>
            <div className='table-responsive'> 
                <table className="table table-hover">
                    
                        <tr>
                            <th>Date Sold:</th>
                            <th>product Name:</th>
                            <th>Quantity Sold:</th>
                            <th>unit price:</th>
                            <th> Total:</th>
                        </tr>
                    
                    <tbody>
                            {currentData.map((r, i)=>(
                                <tr key={i}>
                                <td>{new Date(r.createdAt).toDateString()}</td>
                                <td>{r.product.productname}</td>
                                {/* <td>{r.product ? r.product.productname : 'N/A'}</td> */}
                                <td>{r.Quantity}</td>
                                <td>{r.unitPrice}</td>
                                <td>{r.Total}</td>
                            </tr>
                            ))} 
                    
                    </tbody>
                </table>
            </div>
            <PaginationComponent
                currentPage={currentPage}
                totalItems={sales.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />
        </div>
     );
}
 
export default ShowMatanThreeSalesProduced;