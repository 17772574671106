import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const VendorDetails = () => {
    const { vendor_id } = useParams();
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const token = sessionStorage.getItem("accessToken")

      setLoading(true);
      
        axios.get(`${baseURL}/api/vendor/` + vendor_id, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }) 
          .then(res => {
            setData({
              vendor_id: res.data.vendor_id,
              firstname: res.data.firstname,
              lastname: res.data.lastname,
              phonenumber: res.data.phonenumber,
              email: res.data.email,
              address: res.data.address
            });
          })
          .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        });
      }, [vendor_id]);  

      const [data, setData]=useState({
            firstname: "",
            lastname: "",
            phonenumber: "",
            email: "",
            address: ""
        })

    return (
    <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12 p-5 rounded shadow'>
        
         {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}   
            
            <h5 className='pb-4 display-12 text-danger'> vendor detail</h5>
                    
                     <h5 className="pb-4 display-12">  Vendor id: {data.vendor_id}  </h5>
                     
                     <h5 className="pb-4 display-12" >  Firstname: {data.firstname} </h5>                  

                     <h5 className="pb-4 display-12">  Lastname: {data.lastname}</h5>
             
                     <h5 className="pb-4 display-12">  Phone Number: {data.phonenumber}</h5>
                     
                     <h5 className="pb-4 display-12">  Email: {data.email}</h5>
                    
                     <h5 className="pb-4 display-12"> Physical Address: {data.address} </h5>
                  
                     <hr></hr>
                    <Button variant="danger" type="submit">
                      back to vendor list
                    </Button>
                    
        </div>
    );
      
}
 
export default VendorDetails;


