import { useEffect, useState } from "react";
import axios from 'axios';
import PaginationComponent from "./PaginationComponent";
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const ShowReceivedProduct = () => {

    const [stocks, setStock] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Set the desired number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = stocks.slice(indexOfFirstItem, indexOfLastItem);
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")
        setLoading(true);
        axios.get(`${baseURL}/api/receiveProduct/ReceiveProduct`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
        .then(res =>{setStock(res.data)
            console.log(res.data);
        })
        .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        })
    }, [])

    
    return ( 
        <div>
            
            {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

            <h5 className="mb-3"> Received Product</h5>
            <div className='table-responsive'> 
                <table className="table table-hover">
                    
                        <tr>
                            <th>Date Received:</th>
                            <th>product Name:</th>
                            <th>Quantity Received:</th>
                            <th>Vendor Firstname:</th>
                            <th> Vendor Lastname:</th>
                            <th> Store Name:</th>
                        </tr>
                    
                        <tbody>
                            {currentData.map((r, i)=>(
                                <tr key={i}>
                                <td>{new Date(r.createdAt).toDateString()}</td>
                                <td>{r.productStore.productname}</td>
                                <td>{r.QuantityReceived}</td>
                                <td>{r.storeVendor.firstname}</td>
                                <td>{r.storeVendor.lastname}</td>
                                <td>{r.store.store_name}</td>
                            </tr>
                            ))} 
                    
                        </tbody>
                </table>
            </div>
            <PaginationComponent
                currentPage={currentPage}
                totalItems={stocks.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />

        </div>
     );
}
 
export default ShowReceivedProduct;