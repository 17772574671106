import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Make sure this is after your other CSS imports
import axios from 'axios';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../Auth';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../../spinnerStyles';



const Login = () => {
  const initialValues = { email: '', password: '' };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState(initialValues);
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const {user, login, refreshToken } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const baseURL = process.env.REACT_APP_BASE_URL
  const [loading, setLoading] = useState(false); // State to m

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    setLoading(true)

    try {
      // Send a POST request to the login endpoint with user credentials
      const response = await axios.post(`${baseURL}/api/auth/login`,   formValues);

      if (response.status === 200) {
        const { accessToken, refreshToken } = response.data;
        
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('refreshToken', refreshToken);

        login(accessToken);
        navigate(from, { replace: true }); // Redirect to the intended route
        
      } else if (response.status === 401) {
        // Unauthorized error (invalid username/password)
        // Try refreshing the token and retry login if refresh is successful
        const newAccessToken = await refreshToken();

        if (newAccessToken) {
          // Retry login with the new access token
          await handleSubmit(e);
        } else {
          toast.error('Invalid username/password');
          // setShowLogoutMessage(true);
        }
      }
      
      else {
        
        console.error('Authentication failed');
      }
   
     } catch (error) {
      if (error.response && error.response.status === 400) {
        // Handle validation errors returned by the server
        const apiErrors = error.response.data.error;
    
        // Display the validation errors to the user
        setFormErrors(apiErrors);
      } else if (error.response && error.response.status === 401) {
        // Unauthorized error (invalid username/password)
        toast.error('Invalid username/password')
      } else if (error.response && error.response.status === 404) {
        // Not Found error (user not registered)
        toast.error('User Not registered')
      } else {
        // Handle other types of errors as needed
        toast.error('An error occurred please try again later.')
      }
    }finally {
      setLoading(false); 
    }
    // setShowLogoutMessage(true);
  };
 
  useEffect(() => {
    // Check if the user is already authenticated, then redirect to the intended route
    if (user && user.isAuthenticated) {
      navigate(from, { replace: true });

    } 
    
  }, [user, from, navigate]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormValues({ ...formValues, [name]: value });
// };

// const handleSubmit = async (e) => {
//     e.preventDefault();
//     setFormErrors(validate(formValues));
//     setIsSubmit(true);
//     setLoading(true);

//     try {
//         // Send a POST request to the login endpoint with user credentials
//         const response = await axios.post(`${baseURL}/api/auth/login`, formValues, {
//             withCredentials: true // Ensure Axios sends credentials (cookies)
//         });

//         if (response.status === 200) {
//             // Redirect to the intended route
//             login()
//             navigate(from, { replace: true });
//         } else {
//             console.error('Authentication failed');
//         }
//     } catch (error) {
//         if (error.response && error.response.status === 400) {
//             // Handle validation errors returned by the server
//             const apiErrors = error.response.data.error;

//             // Display the validation errors to the user
//             setFormErrors(apiErrors);
//         } else if (error.response && error.response.status === 401) {
//             // Unauthorized error (invalid username/password)
//             toast.error('Invalid username/password');
//         } else if (error.response && error.response.status === 404) {
//             // Not Found error (user not registered)
//             toast.error('User not registered');
//         } else {
//             // Handle other types of errors as needed
//             toast.error('An error occurred. Please try again later.');
//         }
//     } finally {
//         setLoading(false);
//     }
// };

// useEffect(() => {
//     // Check if the user is already authenticated, then redirect to the intended route
//     if (user && user.isAuthenticated) {
//         navigate(from, { replace: true });
//     }
// }, [user, from, navigate]);

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Username is required!';
    }
    if (!values.password) {
      errors.password = 'Password is required!';
    }
    if (formErrors.email) {
      errors.email = formErrors.email;
    }
    if (formErrors.password) {
      errors.password = formErrors.password;
    }
    return errors;
  };

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '70vh' }}>
      <div className="col-md-4">
    
        <Form onSubmit={handleSubmit} className="p-5 rounded shadow">
        
        {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <h1 class="text-center pb-0 display-7">Sign in</h1>
                <h6 class="text-center pb-4 display-7">to Matan Inventory</h6>
                {formErrors.email && <p className='text-danger'>{formErrors.email}</p>}
                {/* {formErrors.password && <p className='text-danger'>{formErrors.password}</p>} */}
                        
                 <Form.Label>Email address:</Form.Label>
                 <Form.Control type="email" placeholder="Enter username" 
                  name="email" value={formValues.email} autoComplete="off"
                  onChange={handleChange} />
                  {/* <p className='text-danger'>{formErrors.email}</p> */}
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicPassword">
                <Form.Label>Password:</Form.Label>
                <Form.Control type="password" placeholder="Password"
                name="password" value={formValues.password}
                onChange={handleChange}/>
                {/* <p className='text-danger'>{formErrors.password}</p> */}
            </Form.Group>
            
            <Button variant="primary" type="submit" className="mb-4">
                Login
            </Button>          
           
               <Link to='/changePassword' className="collapse-item" style={{float: 'right', textDecoration: 'none'}} >Forgot-Password</Link>
                
         </Form>
          
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;

// import React, { useEffect, useState } from 'react';
// import { Form, Button } from 'react-bootstrap';
// import { ToastContainer, toast } from 'react-toastify';
// import axios from 'axios';
// import { useNavigate, Link, useLocation } from 'react-router-dom';
// import { useAuth } from '../Auth';

// const Login = () => {
//   const initialValues = { email: '', password: '' };
//   const [formValues, setFormValues] = useState(initialValues);
//   const [formErrors, setFormErrors] = useState(initialValues);
//   const [isSubmit, setIsSubmit] = useState(false);
//   const navigate = useNavigate();
//   const { user, login } = useAuth();
//   const location = useLocation();
//   const from = location.state?.from?.pathname || '/';

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormValues({ ...formValues, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setFormErrors(validate(formValues));
//     setIsSubmit(true);

//     try {
//       const response = await axios.post('http://localhost:4000/api/auth/login', formValues);

//       if (response.status === 200) {
//         const { accessToken, refreshToken } = response.data;

//         // Store tokens in sessionStorage
//         sessionStorage.setItem('accessToken', accessToken);
//         sessionStorage.setItem('refreshToken', refreshToken);

//         login(accessToken);

//         navigate(from, { replace: true }); // Redirect to the intended route
//       } else {
//         console.error('Authentication failed');
//       }
//     } catch (error) {
//       handleLoginError(error);
//     }
//   };

//   const handleLoginError = async (error) => {
//     if (error.response && error.response.status === 400) {
//       const apiErrors = error.response.data.error;
//       setFormErrors(apiErrors);
//     } else if (error.response && error.response.status === 401) {
//       toast.error('Invalid username/password');
//     } else if (error.response && error.response.status === 404) {
//       toast.error('User not registered');
//     } else if (error.response && error.response.status === 419) {
//       // Token expired, try to refresh it
//       const newAccessToken = await refreshAccessToken();

//       if (newAccessToken) {
//         // Retry the original request with the new access token
//         await retryOriginalRequest();
//       } else {
//         // Handle refresh token error (e.g., redirect to login page)
//         console.error('Error refreshing access token');
//         toast.error('An error occurred. Please try again later.');
//       }
//     } else {
//       // Handle other types of errors as needed
//       console.error('An error occurred:', error);
//       toast.error('An error occurred. Please try again later.');
//     }
//   };

//   const handleLoginError = async (error) => {
//     if (error.response && error.response.status === 400) {
//       const apiErrors = error.response.data.error;
//       setFormErrors(apiErrors);
//     } else if (error.response && error.response.status === 401) {
//       const responseData = error.response.data;
  
//       if (responseData.error === 'Invalid username/password') {
//         // Handle the case of an invalid username/password
//         toast.error('Invalid username/password');
//       } else if (responseData.error === 'jwt expired') {
//         // Token expired, try to refresh it
//         const newAccessToken = await refreshAccessToken();
  
//         if (newAccessToken) {
//           // Retry the original request with the new access token
//           try {
//             const originalResponse = await axios.post('http://localhost:4000/api/auth/login', {
//               // Include any necessary data for the original request
//             });
  
//             // Process the response as needed for the retried request
//             console.log('Original request response:', originalResponse.data);
//           } catch (originalError) {
//             // Handle errors for the retried request as needed
//             console.error('Error retrying original request:', originalError);
//           }
//         } else {
//           // Handle refresh token error (e.g., redirect to login page)
//           console.error('Error refreshing access token');
//           toast.error('An error occurred. Please try again later.');
//         }
//       } else {
//         // Handle unexpected response content
//         console.error('Unexpected retried request response:', responseData);
//       }
//     } else if (error.response && error.response.status === 404) {
//       toast.error('User not registered');
//     } else {
//       // Handle other types of errors as needed
//       console.error('An error occurred:', error);
//       toast.error('An error occurred. Please try again later.');
//     }
//   };
  

//   const refreshAccessToken = async () => {
//     const refreshToken = sessionStorage.getItem('refreshToken');

//     try {
//       const response = await axios.post('http://localhost:4000/api/auth/refresh-token', {
//         refreshToken,
//       });

//       if (response.status === 200) {
//         const newAccessToken = response.data.accessToken;

//         // Update the stored access token
//         sessionStorage.setItem('accessToken', newAccessToken);

//         return newAccessToken;
//       }
//     } catch (error) {
//       // Handle refresh token error (e.g., redirect to login page)
//       console.error('Error refreshing access token:', error);
//       return null;
//     }

//     return null;
//   };

//   const retryOriginalRequest = async () => {
//     // Retry the original request with the new access token
//     try {
//       const response = await axios.post('http://localhost:4000/api/auth/login', {
//         // Include any necessary data for the original request
//       });

//       // Process the response as needed
//       console.log('Original request response:', response.data);
//     } catch (error) {
//       // Handle errors for the retried request as needed
//       console.error('Error retrying original request:', error);
//     }
//   };

//   useEffect(() => {
//     // Check if the user is already authenticated, then redirect to the intended route
//     if (user && user.isAuthenticated) {
//       navigate(from, { replace: true });
//     }
//   }, [user, from, navigate]);

//   const validate = (values) => {
//     const errors = {};
//     if (!values.email) {
//       errors.email = 'Username is required!';
//     }
//     if (!values.password) {
//       errors.password = 'Password is required!';
//     }
//     if (formErrors.email) {
//       errors.email = formErrors.email;
//     }
//     if (formErrors.password) {
//       errors.password = formErrors.password;
//     }
//     return errors;
//   };

//   return (
//     <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '70vh' }}>
//       <div className="col-md-4">
//         <Form onSubmit={handleSubmit} className="p-5 rounded shadow">
//         <Form.Group className="mb-3" controlId="formBasicEmail">
//                 <h1 class="text-center pb-0 display-7">Sign in</h1>
//                 <h6 class="text-center pb-4 display-7">to Matan Inventory</h6>
//                 {formErrors.email && <p className='text-danger'>{formErrors.email}</p>}
//                 {/* {formErrors.password && <p className='text-danger'>{formErrors.password}</p>} */}
                        
//                  <Form.Label>Email address:</Form.Label>
//                  <Form.Control type="email" placeholder="Enter username" 
//                   name="email" value={formValues.email} autoComplete="off"
//                   onChange={handleChange} />
//                   {/* <p className='text-danger'>{formErrors.email}</p> */}
//             </Form.Group>

//             <Form.Group className="mb-4" controlId="formBasicPassword">
//                 <Form.Label>Password:</Form.Label>
//                 <Form.Control type="password" placeholder="Password"
//                 name="password" value={formValues.password}
//                 onChange={handleChange}/>
//                 {/* <p className='text-danger'>{formErrors.password}</p> */}
//             </Form.Group>
            
//             <Button variant="primary" type="submit" className="mb-4">
//                   Login
//             </Button>
//                 <Link to='/changePassword' className="collapse-item" style={{float: 'right', textDecoration: 'none'}} >Forgot-Password</Link>
//         </Form>
//       </div>
//       <ToastContainer />
//     </div>
//   );
// };

// export default Login;


