import { Button, Dropdown, Form } from "react-bootstrap"
import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import {ToastContainer, toast} from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AuthorizationError from "./AuthorizationError";
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const ReadyMadeProduct = () => {

const [data, setData]=useState({
    productname: "",
    brand: "",
    price: 0.0
})
const baseURL = process.env.REACT_APP_BASE_URL
const [loading, setLoading] = useState(false);
      
const handleCreate =(e)=>{
    setData({...data, [e.target.name]: e.target.value})
    }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [unauthorized , setUnauthorized] = useState(false)
    
  const CreateProduct =(e)=>{
        e.preventDefault()

     const token = sessionStorage.getItem("accessToken")

    const requestData = { ...data };

    setLoading(true);

        axios.post(`${baseURL}/api/productStore/addProduct`, requestData,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
            // .then(res=>console.log(res))
            .then(res => {
                toast.success('Product Added successfully', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                
            })
        
            // .catch(err=>console.log(err.message))    
            .catch(err=>{
                toast.error('An error occurred while adding the record.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                 })
        }).finally(()=>{
            setLoading(false);
        })    
    }
  
  const [records, setRecords]= useState([])
      useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")
        
        setLoading(true);

          axios.get(`${baseURL}/api/productStore/allProducts`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
          })
          .then(res =>{setRecords(res.data)})
          .catch(err => {
            if (err.response.status === 403) {
                setUnauthorized(true);
              }
          }).finally(()=>{
            setLoading(false);
        })
      }, [])


      return (
        <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12'>
          {unauthorized ? (
            <AuthorizationError />
          ) : (
            <div>
                {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

              <Button variant="success" type="submit" className='btn-sm mb-3' onClick={handleShow}>
                      ADD NEW PRODUCT
              </Button>
  
              <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
             
              <Form onSubmit={CreateProduct} >  
                  <Modal.Header closeButton>
                  <h5>add product</h5>
                  </Modal.Header>
                      <Modal.Body>
                        <Form.Group className='mb-3'>
                            <Form.Label> Product Name:</Form.Label>
                            <Form.Control onChange={handleCreate} required type="text" name="productname" placeholder='Enter product name' />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label> Brand:</Form.Label>
                            <Form.Control onChange={handleCreate} required type="text" name="brand" placeholder='Enter product brand' />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label> Price:</Form.Label>
                            <Form.Control onChange={handleCreate} required type="number" name="price" placeholder='Enter price' />
                        </Form.Group>

                      </Modal.Body>
                  <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                          Close
                      </Button>
                      <Button variant="primary" type="submit">
                          Add Product
                      </Button>
                  <ToastContainer/>
                  </Modal.Footer>   
                  </Form>
              </Modal>
  
              <div>
                  <table className="table table-hover table-sm">
                      <thead>
                          <tr>
                              <th>Product name</th>
                              <th> Brand</th>
                              <th> Price</th>
                              <th>Actions</th>
                          </tr>
                      </thead> 
                      <tbody>
                          {records.map((r, i)=>(
                              <tr key={i}>
                              <td>{r.productname}</td>
                              <td>{r.brand}</td>
                              <td>{r.price}</td>
                              <td>
                              <Dropdown>
                                <Dropdown.Toggle variant="default" id="dropdown-basic" size="sm">
                                    Perform Actions
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Link to={`/UpdateProduct/${r.product_id}`} className="dropdown-item">Edit Product</Link>
                                    {/* <Link to={`/DeleteMaterial/${r.product_id}`} className="dropdown-item" >Delete</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                              </td>
                          </tr>
                          ))}
                      </tbody>
                  </table>
              </div>
          </div>
          )}
        </div>
      );
}
 
export default ReadyMadeProduct;