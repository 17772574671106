import Button from 'react-bootstrap/Button';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import PaginationComponent from './PaginationComponent';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthorizationError from './AuthorizationError';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const AllWorkshopVendorPayment = () => {

   

    const [records, setRecords]= useState([])
    const [unauthorized, setUnauthorized] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // number of items to be displayed per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = records.slice(indexOfFirstItem, indexOfLastItem);
    const [loading, setLoading] = useState(false);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const baseURL = process.env.REACT_APP_BASE_URL

    // const token = sessionStorage.getItem("accessToken");
   
    const navigate = useNavigate();
    
    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);
        axios.get(`${baseURL}/api/WorkShopVendorPayment/AllworkshopVendorPayment`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
       
        .then(res =>{setRecords(res.data)})
        // .catch(err => console.log(err)
        .catch(err => {
            if (err.response.status === 403) {
                setUnauthorized(true);
              }
        }).finally(()=>{
            setLoading(false);
        })
            
        
        
    }, [])


    return (
        <div>
            {unauthorized ? (
                <AuthorizationError />
            ) : (
         <>
         {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
         
            <h5 className='mb-3'> Workshop Vendor Payment </h5>

            <div className='table-responsive'> 

                <table className="table table-bordered table-md">
                    <thead>
                        <tr>
                            <th>Date Paid</th>
                            <th>Vendor Name</th>
                            <th>Material Supplied</th>
                            <th>Description</th>
                            <th>Amount Paid</th>
                        </tr>
                    </thead> 
                    <tbody>
                        {currentData.map((r, i)=>(
                            <tr key={i}>
                            <td>{new Date(r.createdAt).toDateString()}</td>
                            <td>{r.vendorname}</td>
                            <td>{r.materialSupplied}</td>
                            <td>{r.Description}</td>
                            <td>{r.AmountPaid}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <PaginationComponent
                currentPage={currentPage}
                totalItems={records.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />
         </>
            )}
        </div>
    );
}
 
export default AllWorkshopVendorPayment;

