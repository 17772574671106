import Button from 'react-bootstrap/Button';
import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import PaginationComponent from './PaginationComponent';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dropdown } from 'react-bootstrap';
import AuthorizationError from './AuthorizationError';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const ShowSuppliers = () => {

   

    const [records, setRecords]= useState([])
    const [unauthorized, setUnauthorized] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Set the desired number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = records.slice(indexOfFirstItem, indexOfLastItem);
    const [loading, setLoading] = useState(false); 

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const baseURL = process.env.REACT_APP_BASE_URL
 

    // useEffect(()=>{
    //     const token = sessionStorage.getItem("accessToken")

    //     axios.get('http://localhost:4000/api/vendor/allVendors', {
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //             'Content-Type': 'application/json',
    //           },
    //     })
       
    //     .then(res =>{setRecords(res.data)})
    //     .catch(err => console.log(err))
        
    // }, [])

    const token = sessionStorage.getItem("accessToken");
   

    const navigate = useNavigate();

    const loadEdit=(id)=>{
        // navigate("/api/measurement/" + id);
        navigate("/UpdateVendor/" + id);
    }

    const LoadVendor=(id)=>{
        // navigate("/api/measurement/" + id);
        navigate("/VendorDetails/" + id);
        
    }

    const LoadDelete=(id)=>{
        // navigate("/api/measurement/" + id);
        navigate("/DeleteVendor/" + id);
        
    }

    
    useEffect(() => {
        // Track whether the effect has run
        let isMounted = true;
        
        const apiCall = async () => {
            try {
                if (!token) {
                    console.log("Token not defined. Skipping API call.");
                    return;
                }
                setLoading(true);

                const response = await axios.get(`${baseURL}/api/vendor/allVendors`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

    
                // Check if the component is still mounted before updating state
                if (isMounted) {
                    setRecords(response.data);
                }
            } catch (error) {
                if (isMounted) {
                    if (error.response.status === 403) {
                        // const localNavigate = navigate;
                        // toast.error("Token expired. Please login again, to Continue...", {
                        //     position: toast.POSITION.TOP_RIGHT,
                        //     autoClose: 3000,
                        //     onClose: () => {
                        //         // Redirect to the login page
                        //         navigate('/login');
                        //     },
                        // });
                        setUnauthorized(true);
                    } else {
                        console.log(error);
                    }
                }
            }
            finally {
                setLoading(false); 
              }
        };
    
        apiCall();
    
        // Cleanup function to set isMounted to false when the component unmounts
        return () => {
            isMounted = false;
        };
    }, [token]);


    return (
        <div>
            {unauthorized ? (
                <AuthorizationError />
            ) : (
         <>
          {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
     <h5> All Vendors</h5>
        <div className='table-responsive'>
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Physical Address</th>
                        <th>Action</th>
                    </tr>
                </thead> 
                <tbody>
                    {currentData.map((r, i)=>(
                        <tr key={i}>
                        <td>{r.firstname}</td>
                        <td>{r.lastname}</td>
                        <td>{r.phonenumber}</td>
                        <td>{r.email}</td>
                        <td>{r.address}</td>
                        <td>
                        <Dropdown>
                                <Dropdown.Toggle variant="default" id="dropdown-basic" size="md">
                                    Perform Actions
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Link to="/action-1" className="dropdown-item" onClick={(e) => { e.preventDefault(); LoadVendor(r.vendor_id)}}>
                                    Details
                                    </Link>
                                    <Link to="/UpdateUser" className="dropdown-item" onClick={(e) => { e.preventDefault(); loadEdit(r.vendor_id)}} >
                                    Edit Vendor
                                    </Link>
                                    {/* <Link to="/delete" className="dropdown-item" onClick={(e) => { e.preventDefault(); LoadDelete(r.vendor_id)}}>
                                    Delete
                                    </Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                    ))}
                </tbody>
                <ToastContainer/>
            </table>
        </div>
            <PaginationComponent
                currentPage={currentPage}
                totalItems={records.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />
        </>
            )}
        </div>
    );
    
    // return (
    //     <div>
            
    //     </div>
    // );
}
 
export default ShowSuppliers;

// onClick={()=>loadEdit(r.unit_id)}