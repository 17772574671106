import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const UpdateMaterial = () => {
    const { material_id } = useParams();
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.get(`${baseURL}/api/Material/GetMaterial/${material_id}`,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        }) 
          .then(res => {
            setData({
              material_id: res.data.material_id,
              material_name: res.data.material_name,
              price: res.data.price
            });
          })
          .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        });
      }, [material_id]);  

    const [data, setData] = useState({
        material_id: "",
        material_name: "",
        price: 0.0
      });

    const handleChange =(e)=>{
        setData({...data, [e.target.name]: e.target.value})
    }  
//..................................................... 
    const updateMaterial = (e)=>{
        e.preventDefault()
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.patch(`${baseURL}/api/Material/UpdateMaterial/` + material_id, data,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
            // .then(res=>console.log(res))
            .then(res =>{
                toast.success('Material updated successfully',{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                })
                // navigate('/UnitOfMeasurement')
            })
            
            // .catch(err=>console.log(err.message))    
            .catch(err=>{
                toast.error('An error occurred while updating the record.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  })
            }).finally(()=>{
                setLoading(false);
            })      
    }
    
    return (
    <div>
        
    {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

        <Form onSubmit={updateMaterial}>   
                
                <h4 className="mb-3">Update raw material</h4>

                        <Form.Group className="mb-3" controlId="material_id">
                        <Form.Label>Material Id:</Form.Label>
                        <Form.Control
                            type="input"
                            required onChange={handleChange}
                            value={data.material_id}
                            name="material_id"
                            disabled="disabled"
                        />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="unit">
                        <Form.Label>MaterialName:</Form.Label>
                        <Form.Control
                            type="input"
                            placeholder="Enter unit of Measurement"
                            required onChange={handleChange}
                            value={data.material_name}
                            name="material_name"
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="price">
                        <Form.Label>Price:</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter price"
                            required onChange={handleChange}
                            value={data.price}
                            name="price"
                        />
                        </Form.Group>
                   
                    <Button variant="primary" type='submit'>
                        Update Material
                    </Button>
                    <ToastContainer/>

        </Form>
    </div> 
    );
      
}
 
export default UpdateMaterial;


