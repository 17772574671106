import React from 'react';

const AuthorizationError = () => {
  
  return (
    <>
  {/* <!-- 404 Error Text --> */}
  <div className="text-center">
    <div className="error mx-auto ml-2" style={{ fontSize: '4rem' }} data-text="Denied">
      Denied
    </div>
    <p className="lead text-gray-800 mb-5">Sorry, you are NOT authorized to perform this action</p>
    <p className="text-gray-500 mb-0">
      You don't have permission to perform this action...
    </p>
    {/* <a href="index.html">&larr; Back to Dashboard</a> */}
  </div>
</>
  );
};

export default AuthorizationError;

