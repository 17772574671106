import { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";


const DeleteVendor = () => {
    const { vendor_id } = useParams();
    const baseURL = process.env.REACT_APP_BASE_URL

    useEffect(() => {
      const token = sessionStorage.getItem("accessToken")

        axios.get(`${baseURL}/api/vendor/` + vendor_id, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }) 
          .then(res => {
            setData({
              vendor_id: res.data.vendor_id,
              firstname: res.data.firstname,
              lastname: res.data.lastname,
              phonenumber: res.data.phonenumber,
              email: res.data.email,
              address: res.data.address
            });
          })
          .catch(err => console.log(err));
      }, [vendor_id]);  

      const [data, setData]=useState({
            firstname: "",
            lastname: "",
            phonenumber: "",
            email: "",
            address: ""
        })
    
    
    return (
        <div >
            <h5 className="text-danger pb-4 display-12">Are you Sure you want to Delete the Record Below ?</h5>

                     <h5 className="pb-4 display-12">  Vendor id: {data.vendor_id}  </h5>
                     
                     <h5 className="pb-4 display-12" >  Firstname: {data.firstname} </h5>                  

                     <h5 className="pb-4 display-12">  Lastname: {data.lastname}</h5>
             
                     <h5 className="pb-4 display-12">  Phone Number: {data.phonenumber}</h5>
                     
                     <h5 className="pb-4 display-12">  Email: {data.email}</h5>
                    
                     <h5 className="pb-4 display-12"> Physical Address: {data.address} </h5>
                  
                     <hr></hr>
                     <Button variant="danger" type="submit" className="mr-3">
                    yes delete vendor
                    </Button> 

                    <Button variant="primary" type="submit" >
                    no don't delete
                    </Button> 
        </div>
     );
}
 
export default DeleteVendor;