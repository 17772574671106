import React from 'react'

export default function Footer() {
  return (
    <>
        <footer class="sticky-footer bg-white">
                <div class="container my-auto">
                    <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Edd Technologies 0725-169182</span>
                    </div>
                </div>
            </footer>
    </>
  )
}
