import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {ToastContainer, toast} from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import AuthorizationError from './AuthorizationError';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const AddMaterial = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [unauthorized, setUnauthorized] = useState(false);

  const [data, setData]=useState({
    material_name: "",
    price: 0.0
})

const handleChange =(e)=>{
    setData({...data, [e.target.name]: e.target.value})
}

const baseURL = process.env.REACT_APP_BASE_URL
const [loading, setLoading] = useState(false);

  const saveMaterial = (e)=>{
    e.preventDefault()

    const requestData = { ...data};
    const token = sessionStorage.getItem("accessToken")

        // console.log(requestData)
        setLoading(true);

    axios.post(`${baseURL}/api/Material/addMaterial`, requestData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
    })
        // .then(res=>console.log(res))
        .then(res =>{

            // Update the list of records after successfully adding a new material
            setRecords([...records, res.data]);
            
            toast.success('New Material added Successfully',{
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            })
        })
        // .catch(err=>console.log(err.message))    
        .catch(err=>{
            if (err.response.status === 403) {
                setUnauthorized(true);
            }
            // toast.error('An error occurred while adding the record.', {
            //     position: toast.POSITION.TOP_RIGHT,
            //     autoClose: 3000,
            //   })
              console.log(err)
        }).finally(()=>{
            setLoading(false);
        })     
}

const [records, setRecords]= useState([])
    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.get(`${baseURL}/api/Material/allMaterial`,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
        .then(res =>{setRecords(res.data)})
        .catch(err => {
            if (err.response.status === 403) {
                setUnauthorized(true);
            }
        }).finally(()=>{
            setLoading(false);
        })
    }, [])


    return (
        <div>
            {unauthorized ? (
                <AuthorizationError />
            ) : (
        <>
        {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
        
            <Button variant="success" type="submit" className='btn-sm mb-3' onClick={handleShow}>
                    ADD NEW RAW-MATERIAL
            </Button>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
           
            <Form onSubmit={saveMaterial} >  
                <Modal.Header closeButton>
                <h5>add new material</h5>
                </Modal.Header>
                    <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Material Name:</Form.Label>
                        <Form.Control type="text" name="material_name" required placeholder="Enter Material name" onChange={handleChange} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Price:</Form.Label>
                        <Form.Control type="text" name="price" required placeholder="Enter price of material" onChange={handleChange} />
                    </Form.Group>


                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit">
                        Add Material
                    </Button>
                <ToastContainer/>
                </Modal.Footer>   
                </Form>
            </Modal>

            <div>
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            {/* <th className="text-center">Material Id</th> */}
                            <th className="text-left"> Material Name</th>
                            <th className="text-left"> Price</th>
                            <th className="text-left">Actions</th>
                        </tr>
                    </thead> 
                    <tbody>
                        {records.map((r, i)=>(
                            <tr key={i}>
                            {/* <td className="text-center">{r.material_id}</td> */}
                            <td className="text-left">{r.material_name}</td>
                            <td className="text-left">{r.price}</td>
                            <td className="text-left">
                            <Dropdown>
                                <Dropdown.Toggle variant="default" id="dropdown-basic" size="sm">
                                    Perform Actions
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Link to={`/UpdateMaterial/${r.material_id}`} className="dropdown-item">Edit Material</Link>
                                    {/* <Link to={`/DeleteMaterial/${r.material_id}`} className="dropdown-item" >Delete</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                </div>                   
        </>
            )}
        </div>
    );
}
 
export default AddMaterial;