import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';



const UpdateProduct = () => {
    const { product_id } = useParams();
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.get(`${baseURL}/api/productStore/getProduct/${product_id}`,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        }) 
          .then(res => {
            setData({
              product_id: res.data.product_id,
              productname: res.data.productname,
              brand: res.data.brand,
              price: res.data.price
            });
          })
          .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        });
      }, [product_id]);  

    const [data, setData] = useState({
        product_id: "",
        productname: "",
        brand: "",
        price: 0.0
      });

    const handleChange =(e)=>{
        setData({...data, [e.target.name]: e.target.value})
    }  
//..................................................... 
    const updateProduct = (e)=>{
        e.preventDefault()
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.patch(`${baseURL}/api/productStore/UpdateProduct/` + product_id, data,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
            // .then(res=>console.log(res))
            .then(res =>{
                toast.success('Product updated successfully',{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                })
                // navigate('/UnitOfMeasurement')
            })
            
            // .catch(err=>console.log(err.message))    
            .catch(err=>{
                toast.error('An error occurred while updating the record.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  })
            }).finally(()=>{
                setLoading(false);
            })      
    }
    
    return (
    <div>
     {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

        <Form onSubmit={updateProduct}>   
                
                <h5 className="mb-3">Update Product</h5>

                        <Form.Group className="mb-3" controlId="material_id">
                        <Form.Label>Product Id:</Form.Label>
                        <Form.Control
                            type="input"
                            required onChange={handleChange}
                            value={data.product_id}
                            name="product_id"
                            disabled="disabled"
                        />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="productname">
                        <Form.Label>Product Name:</Form.Label>
                        <Form.Control
                            type="input"
                            placeholder="Enter product name"
                            required onChange={handleChange}
                            value={data.productname}
                            name="productname"
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="brand">
                        <Form.Label>Brand:</Form.Label>
                        <Form.Control
                            type="input"
                            placeholder="Enter Brand"
                            required onChange={handleChange}
                            value={data.brand}
                            name="brand"
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="price">
                        <Form.Label>Price:</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter Price"
                            required onChange={handleChange}
                            value={data.price}
                            name="price"
                        />
                        </Form.Group>
                   
                    <Button variant="primary" type='submit'>
                        Update Product
                    </Button>
                    <ToastContainer/>

        </Form>
    </div> 
    );
      
}
 
export default UpdateProduct;


