import React, { useEffect, useState } from "react";
import axios from 'axios';
import PaginationComponent from "./PaginationComponent";
import AuthorizationError from "./AuthorizationError";
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const ShowReceivedStock = () => {
    const [stocks, setStock] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Set the desired number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = stocks.slice(indexOfFirstItem, indexOfLastItem);
    const [unauthorized, setUnauthorized] = useState(false)
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.get(`${baseURL}/api/ShowreceivedStock/ReceivedStock`,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
        .then(res => {
            setStock(res.data);
        })
        .catch(err => {
            if (err.response.status === 403) {
                setUnauthorized(true);
              }
        }).finally(()=>{
            setLoading(false);
        });
    }, []);


    return (
        <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12'>
            
            {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

          {unauthorized ? (
            <AuthorizationError />
          ) : (
            <div>
            <h5>Received Stock</h5>
            <div className='table-responsive'> 
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Date Received:</th>
                            <th>Material Name:</th>
                            <th>Quantity Received:</th>
                            <th>Vendor Firstname:</th>
                            <th>Vendor Lastname:</th>
                        </tr>
                    </thead>
                    {/* <tbody>
                        {currentData.map((r, i) => (
                            <tr key={i}>
                                <td>{new Date(r.createdAt).toDateString()}</td>
                                <td>{r.Material.material_name}</td>
                                <td>{r.QuantityReceived}</td>
                                <td>{r.vendor.firstname}</td>
                                <td>{r.vendor.lastname}</td>
                            </tr>
                        ))} 
                    </tbody> */}

                    <tbody>
                        {currentData.map((r, i) => (
                            <tr key={i}>
                                <td>{new Date(r.createdAt).toDateString()}</td>
                                <td>{r.Material ? r.Material.material_name : 'N/A'}</td>
                                <td>{r.QuantityReceived}</td>
                                <td>{r.vendor ? r.vendor.firstname : 'N/A'}</td>
                                <td>{r.vendor ? r.vendor.lastname : 'N/A'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <PaginationComponent
                currentPage={currentPage}
                totalItems={stocks.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />
         </div>
          )}
        </div>
      );
}

export default ShowReceivedStock;


