import { useEffect, useState } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Form } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import AuthorizationError from "./AuthorizationError";
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';


const MatanOneStock = () => {
  const baseURL = process.env.REACT_APP_BASE_URL

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [unauthorized, setUnauthorized] = useState(false);
  const [sales, setSales] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [loading, setLoading] = useState(false);


    const handleChange =(e)=>{
        setData({...data, [e.target.name]: e.target.value})
    }

    const [data, setData]=useState({
        product_id: "",
        Quantity: "",
        destinationStoreId: ""
    })

    const transferProduct = (e)=>{
        e.preventDefault()
    
        const requestData = { ...data};
        const token = sessionStorage.getItem("accessToken")
    
            // console.log(requestData)
        setLoading(true);

        axios.post(`${baseURL}/api/TransferProduct/ReadyMade1`, requestData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
            // .then(res=>console.log(res))
            .then(res =>{
    
                // Update the list of records after successfully adding a new material
                setRecords([...records, res.data]);
                
                toast.success('Transfer Successfull',{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                })
            })
            // .catch(err=>console.log(err.message))    
            .catch(err=>{
                if (err.response.status === 403) {
                    setUnauthorized(true);
                }
                // toast.error('An error occurred while adding the record.', {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 3000,
                //   })
                  console.log(err)
            }).finally(()=>{
                setLoading(false);
            })
          
    }

    const handleChange2 = (e) => {
        const newSelectedProductId = e.target.value;
        setData({...data , product_id: newSelectedProductId })
        setSelectedProductId(newSelectedProductId);
      };

    const [records, setRecords]= useState([])

    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")
        
        setLoading(true);

        axios.get(`${baseURL}/api/Sales/stockMatanOne`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
        .then(res =>{setSales(res.data)
            console.log(res.data);
        })
        .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        })

    }, [])

    useEffect(() => {
        const token = sessionStorage.getItem('accessToken');
    
        axios
          .get(`${baseURL}/api/productStore/allproducts`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          })
          .then((res) => setProducts(res.data))
          .catch((err) => console.log(err));
      }, []);
    
    return ( 
        
        <div>
        {unauthorized ? (
            <AuthorizationError />
        ) : (
    <>
    {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

        <Button variant="success" type="submit" className='btn-sm mb-3' onClick={handleShow}>
                Transfer Product
        </Button>
        {/* <Button variant="danger" type="submit" className='btn-sm mb-3 ml-3' onClick={"/ReceivedProducts"}>
                Transfer Received
        </Button> */}
        <Link to="/ReceivedProducts" className="btn btn-danger btn-sm mb-3 ml-3" >Transfer Received</Link>

        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
       
        <Form onSubmit={transferProduct} >  
            <Modal.Header closeButton>
            <h5>transfer product</h5>
            </Modal.Header>
                <Modal.Body>
               
                <Form.Label>Product Name:</Form.Label>
                    <select className='form-control mb-3' name="product_id" value={selectedProductId} onChange={handleChange2}>
                    <option value="">Select Product</option>
                    {products.map((product) => (
                        <option key={product.id} value={product.product_id}>
                        {`${product.product_id} - ${product.productname}`}
                        </option>
                    ))}
                    </select>
               
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Quantity:</Form.Label>
                    <Form.Control type="text" name="Quantity" required placeholder="Enter Quantity" onChange={handleChange} />
                </Form.Group>
                <Form.Label>Select Store:</Form.Label>
                    <select name="destinationStoreId" className='form-control mb-3' onChange={handleChange}>
                    <option value="">Select Store</option>
                    <option value="2">Matan 2</option>
                    <option value="3">Matan 3</option>
                    
                    </select>
                </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" type="submit">
                    transfer product
                </Button>
            <ToastContainer/>
            </Modal.Footer>   
            </Form>
        </Modal>

        <div>
           
            <h5 className="mp-3"> Matan One Stock</h5>
            <table className="table table-hover">
                
                    <tr>
                        <th>product Name:</th>
                        <th>Quantity-instock:</th>
                    </tr>
                
                <tbody>
                        {sales.map((r, i)=>(
                            <tr key={i}>
                            <td>{r.productname}</td>
                            <td>
                                {r.productInventories.length > 0 ? (
                                    r.productInventories.map((inventory, j) => (
                                    <div key={j}>{inventory.Quantity}</div>
                                    ))
                                ) : (
                                    'N/A'
                                )}
                            </td> 
                           </tr>
                           
                         ))} 
                   
                </tbody>
            </table>
        </div>
                
    </>
        )}
    </div>
     );
     
}
 
export default MatanOneStock;