import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {ToastContainer, toast} from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import PaginationComponent from './PaginationComponent';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';


const MakeSaleInstallements = () => {
const baseURL = process.env.REACT_APP_BASE_URL
const [loading, setLoading] = useState(false);

const [customers, setCustomers] = useState([]);
const [selectedCustomerId, setSelectedCustomerId] = useState('');

const navigate = useNavigate();
const { customer_id } = useParams();

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [data, setData]=useState({
    material_name: "",
    price: 0.0
})

const [records, setRecords]= useState([])
const [currentPage, setCurrentPage] = useState(1);
const itemsPerPage = 10; // Set the desired number of items per page

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentData = records.slice(indexOfFirstItem, indexOfLastItem);

const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
};

const handleChange=(e)=>{
    const newSelectedCustomerId = e.target.value;
    setInfo({...info, customer_id: newSelectedCustomerId})
    setSelectedCustomerId(newSelectedCustomerId);     
};

const handleInput=(e)=>{
    setInfo({...info, [e.target.name]: e.target.value})
};

const [info, setInfo]=useState({
    product_name: "",
    quantity: 0,
    unitPrice: 0,
    AmountPaid: 0,
    balance: 0,
    TotalToPay: 0,
    customer_id: "",
    Branch:""
  })

    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

    axios.get(`${baseURL}/api/customers/allCustomers`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    })
    .then(res =>{setCustomers(res.data)}).finally(()=>{
            setLoading(false);
        })


    .catch(err => console.log(err))
    }, [])

    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")

    axios.get(`${baseURL}/api/PaymentInstallement/allPayments`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    })
    .then(res =>{setRecords(res.data)})

    .catch(err => console.log(err))
    }, [])

    const MakePayment =(e)=>{
        e.preventDefault()
    
        const token = sessionStorage.getItem("accessToken")
        
        const requestData = { ...info };
        const baseURL = process.env.REACT_APP_BASE_URL

        setLoading(true);

        axios.post(`${baseURL}/api/PaymentInstallement/addPayment`, requestData, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
        })
          //   .then(res=>console.log(requestData))
            .then(res =>{
                toast.success('Payment done',{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                })
            })
            // .catch(err=>console.log(err.message))    
            .catch(err=>{
                toast.error('An error occurred while making the payment.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  })
            }).finally(()=>{
                setLoading(false);
            })      
      }

      const loadEdit=(id)=>{
    
        navigate("/UpdatePaymentInstallement/" + id);
    }

    return (
        <div>
            {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
            
            <Button variant="success" type="submit" className='btn-sm mb-3' onClick={handleShow}>
                    NEW PAYMENT
            </Button>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
             <Form onSubmit={MakePayment}>
                    <Modal.Header closeButton>
                        <h6>make sale - installements </h6>
                    </Modal.Header>
                    <Modal.Body>
                {/* code to bind material with drop down to get material id and name */}
                
                    {/* should get this from the drop down in the customer table will change to that */}
                    <Form.Label>Customer Name:</Form.Label>
                    <select className='form-control mb-3' name="customer_name" value={selectedCustomerId} onChange={handleChange}>
                        <option value="">Select customer</option>
                        {customers.map(customer => (
                        <option key={customer.id} value={customer.customer_id}>
                            {`${customer.customer_id} - ${customer.customer_name}`}
                        </option>
                        ))}
                    </select>

                    <Form.Label> Branch </Form.Label>
                    <select className='form-control mb-3' name="Branch" onChange={handleInput}>
                        <option value="">Select Branch</option>
                        
                        <option value="matan 1"> Matan 1</option>
                        <option value="matan 2"> Matan 2</option>
                        <option value="matan 2"> Matan 3</option>

                    </select>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Product Name:</Form.Label>
                        <Form.Control type="text" required onChange={handleInput} placeholder="Enter product name" name="product_name" />
                    </Form.Group>                            
            
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Quantity:</Form.Label>
                        <Form.Control type="number" required onChange={handleInput} placeholder="Quantity" name="quantity" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Unit price:</Form.Label>
                        <Form.Control type="number" required placeholder="enter unit price" onChange={handleInput} name="unitPrice" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Amount Paid:</Form.Label>
                        <Form.Control type="number" required onChange={handleInput} placeholder="Amount Paid" name="AmountPaid" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Total Amount to Pay:</Form.Label>
                        <Form.Control type="number" required onChange={handleInput} placeholder="Amount to Pay" name="TotalToPay" />
                    </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Make Payment
                        </Button>
                    <ToastContainer/>
                    </Modal.Footer>   
            </Form>
            
            </Modal>

            <div className='table-responsive'>
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            {/* <th className="text-center">Material Id</th> */}
                            <th className="text-left"> Date of Payment</th>
                            <th className="text-left"> Product Name</th>
                            <th className="text-left"> Quantity</th>
                            <th className="text-left">Unit Price</th>
                            <th className="text-left">Amount Paid</th>
                            <th className="text-left">Balance</th>
                            <th className="text-left">Total to Pay</th>
                            <th className="text-left">Customer Name</th>
                            <th className="text-left">Branch </th>
                            <th className="text-left">Action</th>
                        </tr>
                    </thead> 
                    <tbody>
                        {currentData.map((r, i)=>(
                            <tr key={i}>
                            {/* <td className="text-center">{r.material_id}</td> */}
                            <td>{new Date(r.createdAt).toDateString()}</td>
                            <td className="text-left">{r.product_name}</td>
                            <td className="text-left">{r.quantity}</td>
                            <td className="text-left">{r.unitPrice}</td>
                            <td className="text-left">{r.AmountPaid}</td>
                            <td className="text-left">{r.balance}</td>
                            <td className="text-left">{r.TotalToPay}</td>
                            <td className="text-left">{r.customer.customer_name}</td>
                            <td className="text-left">{r.Branch}</td>
                            <td className="text-left"> 
                            <Dropdown>
                                <Dropdown.Toggle variant="default" id="dropdown-basic" size="sm">
                                    Perform Actions
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {/* <Link to={`/UpdatePaymentInstallment/${r.customer_id}`} className="dropdown-item">Edit</Link> */}
                                    <Link to="/UpdatePaymentInstallement/" className="dropdown-item" onClick={(e) => { e.preventDefault(); loadEdit(r.payment_id)}} >
                                    Update Payment
                                    </Link>
                                    {/* <Link to={`/DeleteMaterial/${r.payment_id}`} className="dropdown-item" >Delete</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
                <PaginationComponent
                currentPage={currentPage}
                totalItems={records.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />
               
            
            
        </div>
    );
}
 
export default MakeSaleInstallements;