import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {ToastContainer, toast} from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Dropdown, Row } from 'react-bootstrap';

const AddStore = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const baseURL = process.env.REACT_APP_BASE_URL

  const [data, setData]=useState({
    store_name: "",
})

const handleChange =(e)=>{
    setData({...data, [e.target.name]: e.target.value})
}
  const saveMaterial = (e)=>{
    e.preventDefault()

    const requestData = { ...data };

    const token = sessionStorage.getItem("accessToken")

    axios.post(`${baseURL}/api/Store/addStore`, requestData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
    })
        // .then(res=>console.log(res))
        .then(res =>{
            toast.success('New Store added Successfully',{
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            })
        })
        // .catch(err=>console.log(err.message))    
        .catch(err=>{
            toast.error('An error occurred while adding the record.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              })
              console.log(err)
        })      
}

// const [store, setStore] = useState([]);

// const handleUnit = (e, index) => {
//     const { name, value } = e.target;
//     const updatedRawMaterials = [...selectedRawMaterials];
//     updatedRawMaterials[index][name] = value;
//     setSelectedRawMaterials(updatedRawMaterials);
//   };
    
//     <select className='form-control mb-3' value={rawMaterial.unit_id || ''} name="store_id" onChange={(e) => handleUnit(e, index)}>
//         <option value="">Select Unit of Measurement</option>
//         {records.map(store => (
//         <option key={store.id} value={store.id}>
//             {`${store.store_id} - ${store.store_name}`}
//         </option>
//         ))}
//     </select>

const [records, setRecords]= useState([])
    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")
        
        axios.get(`${baseURL}/api/Store/allStore`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
        .then(res =>{setRecords(res.data)})
        .catch(err => console.log(err))
    }, [])

    return (
        <div>
            <Row>
                
                <Button variant="primary" type="submit" className='btn-sm mb-3 mr-3' onClick={handleShow}>
                        ADD STORE
                </Button>

                <Dropdown>
                    {/* <Dropdown.Toggle variant="success" className='btn-sm' id="dropdown-basic">
                        SELECT STORE
                    </Dropdown.Toggle> */}

                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="MatanOne" className="my-dropdown-item">
                        Matan 1
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="MatanTwo" className="my-dropdown-item">
                        Matan 2
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="MatanThree" className="my-dropdown-item">
                        Matan 3
                        </Dropdown.Item>
                    </Dropdown.Menu>
                 </Dropdown>    
            </Row>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
           
            <Form onSubmit={saveMaterial} >  
                <Modal.Header closeButton>
                <h5>add store</h5>
                </Modal.Header>
                    <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Store Name:</Form.Label>
                        <Form.Control type="text" name="store_name" placeholder="Enter Store Name" onChange={handleChange} />
                    </Form.Group>

                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit">
                        Add Store
                    </Button>
                <ToastContainer/>
                </Modal.Footer>   
                </Form>
            </Modal>

            <div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Store Id</th>
                            <th> Store Name</th>\
                            {/* <th>Actions</th> */}
                        </tr>
                    </thead> 
                    <tbody>
                        {records.map((r, i)=>(
                            <tr key={i}>
                            <td>{r.store_id}</td>
                            <td>{r.store_name}</td>
                            {/* <td>  */}
                                {/* <Link to={`/UpdateMaterial/${r.store_id}`} className="btn btn-primary btn-sm mr-1">Update</Link> */}
                            {/* </td>  */}
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
 
export default AddStore;