import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import axios from 'axios';
import AuthorizationError from "./AuthorizationError";
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';


const UserManagement = () => {

    const [records, setRecords]= useState([])
    const [unauthorized, setUnauthorized] = useState(false)
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")

            setLoading(true);

        axios.get(`${baseURL}/api/auth/users`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
        .then(res =>{setRecords(res.data)})
        .catch(err => {
            if (err.response.status === 403) {
                setUnauthorized(true);
              }
        }).finally(()=>{
            setLoading(false);
        })
    }, [])

    const navigate = useNavigate();

    const loadEdit=(id)=>{
        // navigate("/api/measurement/" + id);
        navigate("/UpdateUser/" + id);
    }

    const LoadUser=(id)=>{
        // navigate("/api/measurement/" + id);
        navigate("/UserDetails/" + id);
        
    }

    const LoadDelete=(id)=>{
        // navigate("/api/measurement/" + id);
        navigate("/UserManagement/DeleteUser/" + id);
        
    }
// will now create the routes for loadEdit, LoadVendor and LoadDelete

return (
    <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12 p-5'>
      {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
        
      {unauthorized ? (
        <AuthorizationError />
      ) : (
        <div>
            <h5 className='pb-4'> User Management </h5>
            <table className="table table-hover">
                    <thead>
                        <tr>
                            {/* <th>Name</th> */}
                            <th>Email</th>
                            <th>Role</th>
                            <th>Actions</th>
                        </tr>     
                    </thead>
                   <tbody>
                   {records.map((r, i)=>(
                        <tr key={i}>
                        {/* <td> Firstname</td> */}
                        <td>{r.email}</td>
                        <td>{r.role}</td>
                        <td>  
                            <Dropdown>
                                <Dropdown.Toggle variant="default" id="dropdown-basic" size="sm">
                                    Perform Actions
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    
                                    <Link to="/UpdateUser" className="dropdown-item" 
                                        onClick={(e) => { e.preventDefault(); loadEdit(r.id)}} >
                                        Edit User
                                    </Link>
                                    {/* <Link to="/delete" className="dropdown-item" onClick={()=>LoadDelete(r.id)}>
                                    Delete
                                    </Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                        </td> 
                    </tr>
                    ))}
                </tbody>

            </table>
        </div>
      )}
    </div>
  );
}
 
export default UserManagement;