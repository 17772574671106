import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useEffect } from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Category = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [data, setData]=useState({
    category_name: "",
})

const handleChange =(e)=>{
    setData({...data, [e.target.name]: e.target.value})
}
const baseURL = process.env.REACT_APP_BASE_URL


const saveCategory = (e)=>{
    e.preventDefault()

    axios.post(`${baseURL}/api/category/addCategory`, data)
        // .then(res=>console.log(res))
        .then(res =>{
            toast.success('New Category added successfully',{
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            })
        })

        // .catch(err=>console.log(err.message))    
        .catch(err=>{
            toast.error('An error occurred while adding the record.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              })
        })      
}

const [records, setRecords]= useState([])
    useEffect(()=>{
        axios.get(`${baseURL}/api/category/allCategories`)
        .then(res =>{setRecords(res.data)})
        .catch(err => console.log(err))
    }, [])
    return (
        <div>
            <Button variant="success" type="submit" onClick={handleShow} className='mb-3 btn-sm'>
                    ADD NEW CATEGORY
            </Button>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
            
            <Form onSubmit={saveCategory}>   
                <Modal.Header closeButton>
                {/* <h6>add new Category</h6> */}
                <Modal.Title><h6>add new Category</h6></Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                    
                        <Form.Group className="mb-3" controlId="unit">
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                            type="input"
                            placeholder="Enter category"
                            required onChange={handleChange}
                            value={data.unit}
                            name="category_name"
                            autoFocus
                        />
                        </Form.Group>
                    
                
                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type='submit'>
                        Add Category
                    </Button>
                    <ToastContainer/>
                </Modal.Footer>
                </Form>
            </Modal>

            <div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Category Id</th>
                            <th>Category name</th>
                            <th>Action</th>
                        </tr>
                    </thead> 
                    <tbody>
                        {records.map((r, i)=>(
                            <tr key={i}>
                            <td>{r.category_id}</td>
                            <td>{r.category_name}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
 
export default Category;