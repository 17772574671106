import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const UpdateCustomer = () => {
    const { customer_id } = useParams();
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        const token = sessionStorage.getItem("accessToken")
        console.log("Customer ID:", customer_id);

        setLoading(true);

        axios.get(`${baseURL}/api/Customers/getCustomer/${customer_id}`,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        }) 
          .then(res => {
            setData({
              customer_id: res.data.customer_id,
              customer_name: res.data.customer_name,
              customer_mobileNo: res.data.customer_mobileNo
            });
          })
          .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        });
      }, [customer_id]);  

    const [data, setData] = useState({
        customer_id: "",
        customer_name: "",
        customer_mobileNo: ""
      });

    const handleChange =(e)=>{
        setData({...data, [e.target.name]: e.target.value})
    }  
//..................................................... 
    const updateCustomer = (e)=>{
        e.preventDefault()
        const token = sessionStorage.getItem("accessToken")

        // axios.patch('http://localhost:4000/api/Customers/UpdateCustomer/' + customer_id, data,{
        setLoading(true);

        axios.patch(`${baseURL}/api/Customers/UpdateCustomer/${customer_id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
            // .then(res=>console.log(res))
            .then(res =>{
                toast.success('Customer updated successfully',{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                })
                // navigate('/UnitOfMeasurement')
            })
            
            // .catch(err=>console.log(err.message))    
            .catch(err=>{
                toast.error('An error occurred while updating the record.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  })
            }).finally(()=>{
                setLoading(false);
            })     
    }
    
    return (
    <div>

    {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
    
        <Form onSubmit={updateCustomer}>   
                
                <h4 className="mb-3">Update Customer</h4>

                        <Form.Group className="mb-3" controlId="customer_id">
                        {/* <Form.Label>Customer Id:</Form.Label> */}
                        <Form.Control
                            type="input"
                            required onChange={handleChange}
                            value={data.customer_id}
                            name="customer_id"
                            disabled="disabled"
                            hidden
                        />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="unit">
                        <Form.Label>Customer Name:</Form.Label>
                        <Form.Control
                            type="input"
                            placeholder="Enter Customer name"
                            required onChange={handleChange}
                            value={data.customer_name}
                            name="customer_name"
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="mobileNo">
                        <Form.Label>Mobile No:</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter mobile no"
                            required onChange={handleChange}
                            value={data.customer_mobileNo}
                            name="customer_mobileNo"
                        />
                        </Form.Group>
                   
                    <Button variant="primary" type='submit'>
                        Update Customer
                    </Button>
                    <ToastContainer/>

        </Form>
    </div> 
    );
      
}
 
export default UpdateCustomer;


