import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useState, useEffect } from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthorizationError from './AuthorizationError';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const StoreVendorPayment = () => {
    const [products, setProducts] = useState([]);
        const [selectedProductId, setSelectedProductId] = useState('');
        const [unauthorized , setUnauthorized] = useState(false)
        const baseURL = process.env.REACT_APP_BASE_URL
        const [loading, setLoading] = useState(false);

            useEffect(()=>{
                
                const token = sessionStorage.getItem("accessToken")

            setLoading(true);

            axios.get(`${baseURL}/api/productStore/allproducts`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
            })
            .then(res =>{setProducts(res.data)})
            
            .catch(err => {
                if (err.response.status === 403) {
                    setUnauthorized(true);
                  }
            }).finally(()=>{
                setLoading(false);
            })
        }, [])

        const handleChange=(e)=>{
            const newSelectedProductId = e.target.value;
            setInfo({...info, product_id: newSelectedProductId})
            setSelectedProductId(newSelectedProductId);     
        };


        // code to bind vendor with drop down to get vendor id and name
        const [vendors, setVendors] = useState([]);
        const [selectedVendorId, setselectedVendorId] = useState('');
            useEffect(()=>{
             const token = sessionStorage.getItem("accessToken")
            
            axios.get(`${baseURL}/api/StoreVendor/allStoreVendors`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
            })
            .then(res =>{setVendors(res.data)})
            
            .catch(err => {
                if (err.response.status === 403) {
                    setUnauthorized(true);
                  }
            })
        }, [])


        const handleDescription=(e)=>{
            setInfo({...info, [e.target.name]: e.target.value})
        };

        const handleAmount=(e)=>{
            setInfo({...info, [e.target.name]: e.target.value})
        };

        const handleVendor = (e) => {
            const newSelectedVendorId = e.target.value;
            setInfo({ ...info, vendor_id: newSelectedVendorId });
            setselectedVendorId(newSelectedVendorId);
          };

    
        const [info, setInfo]=useState({
            vendorname: "",
            productSupplied: "",
            Description: "",
            AmountPaid: 0,
          })
          
          const Payment =(e)=>{
              e.preventDefault()
          
              const token = sessionStorage.getItem("accessToken")
              
              const requestData = {
                vendorname: selectedVendorId,
                productSupplied: selectedProductId,
                Description: info.Description,
                AmountPaid: info.AmountPaid,
            };
            
            setLoading(true);

              axios.post(`${baseURL}/api/StoreVendor/storeVendorPayment`, requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
              })
                  // .then(res=>console.log(requestData))
                  .then(res =>{
                      toast.success('Payment Saved Successfully',{
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 3000,
                      })
                  })
                  // .catch(err=>console.log(err.message))    
                  .catch(err=>{
                    if (err.response.status === 403) {
                        setUnauthorized(true);
                      }
                  }).finally(()=>{
                    setLoading(false);
                })      
            }


     return (
            <div>
                {unauthorized ? (
                    <AuthorizationError />
                  ) : (
                    
                    <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12 p-5 rounded shadow'>
    
     {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

        <Form onSubmit={Payment}>
        <h5 className='pb-2 display-12'> Store Vendor Payment</h5>

        {/*bind vendor with drop down to get vendor id and name */}
        <Form.Label>Vendor Name:</Form.Label>
            <select className='form-control mb-3' name="vendorname" value={selectedVendorId} onChange={handleVendor}>
                <option value="">Select a Vendor</option>
                {vendors.map(vendor => (
                <option key={vendor.id} value={`${vendor.firstname} ${vendor.lastname}`}>
                    {`${vendor.firstname} ${vendor.lastname}`}
                </option>
                ))}
            </select>

        {/* code to bind material with drop down to get material id and name */}
        <Form.Label>Product Supplied:</Form.Label>
            <select className='form-control mb-3' name="productSupplied" value={selectedProductId} onChange={handleChange}>
                <option value="">Select Product</option>
                {products.map(product => (
                <option key={product.id} value={product.productname}>
                    {product.productname}
                </option>
                ))}
            </select>
     
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Description:</Form.Label>
                <Form.Control as="textarea" rows={3} required onChange={handleDescription} placeholder="More Description about payment" name="Description" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Amount Paid:</Form.Label>
                <Form.Control type="input" required onChange={handleAmount} placeholder="Amount paid" name="AmountPaid" />
            </Form.Group>

            <Button variant="primary" type="submit">
                Pay Vendor
            </Button>
            <ToastContainer/>
        </Form>
    </div>
     )}
 </div>
    );
}
 
export default StoreVendorPayment;