import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const UpdateUser = () => {
    const { id } = useParams();
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.get(`${baseURL}/api/auth/users/` + id,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        }) 
          .then(res => {
            setData({
              id: res.data.id,
              email: res.data.email,
              role: res.data.role,
            });
          })
          .catch(err => console.log(err)).finally(()=>{
            setLoading(false);
        });

      }, [id]);  

      const [data, setData]=useState({
            email: "",
            role: "",
        })
     const navigate = useNavigate();

    const handleChange =(e)=>{
        setData({...data, [e.target.name]: e.target.value})
    }
//..................................................... 
    const saveUser = (e)=>{
        e.preventDefault()

        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.patch(`${baseURL}/api/auth/UpdateUser/${id}`, data,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
            // .then(res=>console.log(res))
            .then(res =>{
                toast.success('User updated successfully',{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                })
                // alert("User updated successfully")
                // navigate("/AllUsers");
            })
            // .catch(err=>console.log(err.message))    
            .catch(err=>{
                toast.error('An error occurred while updating the record.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  })
            }).finally(()=>{
                setLoading(false);
            })     
    }
    return (
    <div className='class="d-flex justify-content-center align-items-center mx-auto col-md-12 p-5 rounded shadow'>
           
           {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}

            <Form onSubmit={saveUser}>
            <h5 className='pb-1 display-12'> Edit User</h5>

                 <Form.Group className="mb-3" controlId="unit_Id">
                     {/* <Form.Label>Vendor Id:</Form.Label> */}
                        <Form.Control type="hidden" required onChange={handleChange}
                        value={data.id} name="user_id" disabled="disabled"/>
                 </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Name:</Form.Label>
                    <Form.Control type="input" onChange={handleChange} name="email" 
                     value={data.email}placeholder="Enter email" readOnly />
                    {/* <p className='text-danger'>{formErrors.firstname}</p> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicRole">
                    <Form.Label>Role:</Form.Label>
                    <Form.Control as="select" onChange={handleChange} name="role" value={data.role} defaultValue={data.role}>
                        <option value="admin">admin</option>
                        <option value="user">user</option>
                        <option value="man1">man1</option>
                        <option value="man2">man2</option>
                        <option value="man3">man3</option>
                        {/* Add more role options as needed */}
                    </Form.Control>
                </Form.Group>

                <Button variant="primary" type="submit">
                    Update User
                </Button>
                <ToastContainer/>
            </Form>
        </div>
    );
      
}
 
export default UpdateUser;


