// import './App.css';
// import { Route, Routes } from 'react-router-dom';
// import Main from './components/Main';

// import "./css/sb-admin-2.min.css";
// import Login from './components/Pages/Login';
// import Register from './components/Pages/Register';
// import ForgotPassword from './components/Pages/ForgotPassword';
// import {AuthProvider, useAuth} from './components/Auth';
// import { useEffect, useState } from 'react';

// function App() {

//   const auth = useAuth();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     // Check for authentication status on app load
//     const storedToken = sessionStorage.getItem("accessToken");
//     if (storedToken) {
//       // If a token is found, the user is authenticated
//       auth.login({/* Set user data here if needed */});
//     }
//     setLoading(false);
//   }, [auth]);

//   if (loading) {
//     // You can show a loading spinner or something while checking auth status
//     return <div>Loading...</div>;
//   }

//   return (
//     <AuthProvider>
//     <Routes>
      
//       <Route path='/*' element={<Main/>}/>
//       <Route path='/Login' element={<Login/>}/>
//       <Route path='/register' element={<Register/>}/>
//       <Route path='/changePassword' element={<ForgotPassword/>}/>
      
//     </Routes>
//     </AuthProvider>

    // <Routes>
    //   <Route path="/*" element={<Main/>}>
    //     <Route path='/Login' element={<Login/>}/>
    //     <Route path='/register' element={<Register/>}/>
    //     <Route path='/changePassword' element={<ForgotPassword/>}/>
    //   </Route>
    // </Routes>
//   );
// }

// export default App;
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Main from './components/Main';
import "./css/sb-admin-2.min.css";
import Login from './components/Pages/Login';
import Register from './components/Pages/Register';
import ForgotPassword from './components/Pages/ForgotPassword';
import { AuthProvider, useAuth } from './components/Auth';
import { useEffect, useState } from 'react';
import LogOut from './components/Pages/LogOut';
import ResetPassword from './components/Pages/ResetPassword';

function App() {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   // Check for authentication status on app load
  //   const storedToken = sessionStorage.getItem("accessToken");
  //   // if (storedToken) {
  //   //   // If a token is found, the user is authenticated
  //   //   auth.login({/* Set user data here if needed */});
  //   // }
  //   setLoading(false);
  // }, [auth]);

  // if (loading) {
  //   // You can show a loading spinner or something while checking auth status
  //   return <div>Loading...</div>;
  // }

  return (
    <AuthProvider>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/changePassword' element={<ForgotPassword />} />
        <Route path='/LogOut' element={<LogOut />} />
        <Route path="/resetPassword/:token" element={<ResetPassword/>} />
        <Route path='/*' element={<Main />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
