import { useEffect, useState } from "react"
import axios from 'axios';
import PaginationComponent from "./PaginationComponent";
import AuthorizationError from './AuthorizationError';
import { ScaleLoader } from 'react-spinners';
import { spinnerOverride } from '../spinnerStyles';

const RawMaterialUsage = () => {

    const [records, setRecords]= useState([])
    const [unauthorized, setUnauthorized] = useState(false);
    const baseURL = process.env.REACT_APP_BASE_URL
    const [loading, setLoading] = useState(false);


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Set the desired number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = records.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    useEffect(()=>{
        const token = sessionStorage.getItem("accessToken")

        setLoading(true);

        axios.get(`${baseURL}/api/RawMaterials/UsedRawMaterial`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
        })
        .then(res =>{setRecords(res.data)})
        .catch(err => {
            if (err.response.status === 403) {
                setUnauthorized(true);
            }
        }).finally(()=>{
            setLoading(false);
        })
    }, [])


    return (
        <div>
            {unauthorized ? (
                <AuthorizationError />
            ) : (
        <>
        {loading && <ScaleLoader color="#36D7B7" loading={loading} css={spinnerOverride} size={150} />}
             <h5> Raw Material Usage</h5>
            <table className="table table-hover">
                
                <tr>
                    
                        <th>Date</th>
                        <th>Product Produced</th>
                        <th>Material Name</th>
                        <th>Quantity Used</th>  
                        {/* <th>UoM</th> */}
                </tr>
                <tbody>
                {currentData.map((r, i)=>(
                            <tr key={i}>
                            <td>{new Date(r.createdAt).toDateString()}</td>
                            <td>{r.product.productname}</td>
                            <td>{r.Material.material_name}</td>
                            <td>{r.quantity}</td>
                            {/* <td>{r.unit.unit_name}</td> */}
                        </tr>
                         ))}   
                    
                </tbody>
            </table>

            <PaginationComponent
                currentPage={currentPage}
                totalItems={records.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
            />
        </>
            )}
        </div>
    );

}
 
export default RawMaterialUsage;